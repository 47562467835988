<div class="login-wrap">
  <div class="login-header">
    <div class="header-logo">
      <img src="assets/images/logos/wemind-blue.svg" alt="WeMind CMS Boost" />
    </div>
    <div class="header-text">Administration</div>
  </div>

  <app-form [form]="form" (formSubmit)="submit()" class="login-form">
    <app-form-field label="Email" class="mb-6">
      <app-input
        [control]="form.controls.username"
        placeholder="Your Email"
        inputType="email"
      />
    </app-form-field>
    <app-form-field label="Password" class="mb-6">
      <app-password-input
        [control]="form.controls.password"
        placeholder="Your password..."
      >
      </app-password-input>
    </app-form-field>
    @if( form.invalid && form.controls.username.touched &&
    form.controls.password.touched ){
    <span class="error-message">{{ errorMessage() }}</span>
    }

    <div class="w-full mt-12">
      <moovd-button [stretch]="true" [isDisabled]="form.invalid">
        <moovd-label
        [loading]="isLoading()"
        >
          {{ isLoading() ? "Loading" : "Login" }}
        </moovd-label>
      </moovd-button>
    </div>
  </app-form>

  <div class="login-footer">
    <a class="footer-logo" href="https://moovd.nl">
      <img src="assets/images/logos/moovd-logo-full.png" alt="moovd" />
    </a>
  </div>
</div>
