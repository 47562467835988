import { StorageValidationErrorCode, validationErrorMap } from '../../../../../errors/types/validation.mjs';
import { StorageError } from '../../../../../errors/StorageError.mjs';
import { calculatePartSize } from './calculatePartSize.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const getDataChunker = (data, totalSize) => {
  const partSize = calculatePartSize(totalSize);
  if (data instanceof Blob) {
    return helper(data, 0, data.size, partSize);
  } else if (ArrayBuffer.isView(data)) {
    return helper(data.buffer, data.byteOffset, data.byteLength, partSize);
  } else if (data instanceof ArrayBuffer) {
    return helper(data, 0, data.byteLength, partSize);
  } else if (typeof data === 'string') {
    const blob = new Blob([data]);
    return getDataChunker(blob, blob.size);
  } else {
    throw new StorageError({
      name: StorageValidationErrorCode.InvalidUploadSource,
      ...validationErrorMap[StorageValidationErrorCode.InvalidUploadSource]
    });
  }
};
const helper = function* (buffer, byteOffset, byteLength, partSize) {
  let partNumber = 1;
  let startByte = byteOffset;
  let endByte = byteOffset + Math.min(partSize, byteLength);
  while (endByte < byteLength + byteOffset) {
    yield {
      partNumber,
      data: buffer.slice(startByte, endByte),
      size: partSize
    };
    partNumber += 1;
    startByte = endByte;
    endByte = startByte + partSize;
  }
  yield {
    partNumber,
    data: buffer.slice(startByte, byteLength + byteOffset),
    size: byteLength + byteOffset - startByte
  };
};
export { getDataChunker };
