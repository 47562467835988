import {
  ChangeDetectionStrategy,
  Component,
  model,
  output,
} from '@angular/core';
import { FormFieldComponent } from '../form';
import { InputComponent } from '../inputs';
import { DropdownComponent, DropdownDirective } from '@directives';
import { TranslateModule } from '@ngx-translate/core';
import { MoovdButton, MoovdLabel, MoovdIcon } from 'moovd-components';
@Component({
  selector: 'app-filter-bar',
  standalone: true,
  imports: [
    DropdownDirective,
    DropdownComponent,
    FormFieldComponent,
    InputComponent,
    TranslateModule,
    MoovdButton,
    MoovdLabel,
    MoovdIcon
  ],
  templateUrl: './filter-bar.component.html',
  styleUrl: './filter-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarComponent {
  public searchKey = model<string>();
  public totalData = model<number>();
  public selectedData = model.required<number>();
  public readonly buttonAction = output<void>();
  public readonly reset = output<void>();

  public buttonActionVoid(): void {
    return this.buttonAction.emit();
  }

  public search(e: Event) {
    const searchValue = (e.target as HTMLInputElement).value;
    this.searchKey.set(searchValue);
  }

  public resetVoid(): void {
    return this.reset.emit();
  }
}
