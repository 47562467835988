import { Component, inject, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { concatMap, timer } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DialogRef } from '@angular/cdk/dialog';

import { ProgressDialogComponent } from '@components';
import {
  CompanyService,
  DialogService,
  LocationService,
  MediaService,
  ProgressService,
  StorageService,
} from '@services';
import { SessionStorageKeyEnum } from '@enums';
import { MediaField } from '@interfaces';

import {
  getInformationConfirmation,
  getAdminConfirmation,
  getProductConfirmation,
  DetailOptionInterface,
} from '@app/feature/company/datas/company-preview.data';
import {
  CompanyField,
  CreateCompanyAdminInterface,
  CreateCompanyDepartmentInterface,
  CreateCompanyInformationInterface,
  CreateCompanyProductInterface,
  FormCompanyAdminInterface,
  FormCompanyDepartmentInterface,
  FormCompanyInformationInterface,
  FormCompanyProductInterface,
} from '@app/feature/company/models';
import { MoovdButton, MoovdIcon, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-company-confirmation-form',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    MoovdButton,
    MoovdLabel,
    MoovdIcon
  ],
  templateUrl: './company-confirmation-form.component.html',
  styleUrl: './company-confirmation-form.component.scss'
})
export class CompanyConfirmationFormComponent {
  private dialogRef: DialogRef<unknown, unknown> | undefined;
  private dialogService = inject(DialogService);

  private readonly field = CompanyField;

  public informationData = signal<DetailOptionInterface[]>([]);
  public adminData = signal<DetailOptionInterface[]>([]);
  public departmentData = signal<string[]>([]);
  public productData = signal<DetailOptionInterface[]>([]);

  private createCompanyInformationData =
    signal<CreateCompanyInformationInterface | null>(null);
  private createCompanyAdminData = signal<CreateCompanyAdminInterface | null>(
    null
  );
  private createCompanyDepartmentData =
    signal<CreateCompanyDepartmentInterface | null>(null);
  private createCompanyProductData =
    signal<CreateCompanyProductInterface | null>(null);

  constructor(
    public mediaService: MediaService,
    private route: Router,
    private companyService: CompanyService,
    private storageService: StorageService,
    private locationService: LocationService,
    private progressService: ProgressService
  ) {}

  private formStep = this.companyService.formStepEnum;

  ngOnInit() {
    this.loadSavedInformationFormData();
    this.loadSavedAdminFormData();
    this.loadSavedDepartmentFormData();
    this.loadSavedProductFormData();
  }

  public getAdminAddress(): string {
    return this.companyService.getFilledAddress().toUpperCase();
  }

  public async back() {
    await this.route.navigate(['superadmin/companies/create/product']);
    this.companyService.formStep.set(this.formStep.COMPANY_PRODUCT);
  }

  public submit() {
    this.openProgressDialog();

    this.progressService.setProgress(27, `Uploading company logo...`);

    this.companyService
      .uploadCompanyLogo({
        title: this.companyService.companyLogoTemp()?.[
          MediaField.TITLE
        ] as string,
        value: this.companyService.companyLogoTemp()?.[
          MediaField.VALUE
        ] as ArrayBuffer,
      })
      .pipe(
        concatMap((res) => {
          this.progressService.setProgress(62, `Uploading company data...`);
          this.createCompanyInformationData.update((data) => ({
            ...(data as CreateCompanyInformationInterface),
            [this.field.COMPANY_LOGO_URL]: res.path,
          }));
          return this.companyService.createCompany(
            this.createCompanyInformationData() as CreateCompanyInformationInterface,
            this.createCompanyAdminData() as CreateCompanyAdminInterface,
            this.createCompanyDepartmentData() as CreateCompanyDepartmentInterface,
            this.createCompanyProductData() as CreateCompanyProductInterface
          );
        })
      )
      .subscribe({
        next: (res) => {
          if (res.errors) {
            if (res.errors[0].message) {
              this.progressService.setProgress(-1, res.errors[0].message);
              return;
            }
          }
          if (res.data) {
            timer(2000).subscribe(() => {
              this.dialogRef?.close();
              this.storageService.sessionStorageClear();
              this.route.navigate(['superadmin/companies/list']);
              return;
            });
          }
        },
        error: (err) => {
          this.dialogRef?.close();
          console.log('err', err);
        },
      });
  }

  private loadSavedInformationFormData() {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyInformationInterface>(
        SessionStorageKeyEnum.COMPANY_INFORMATION_FORM
      );

    if (savedData) {
      savedData[this.field.COMPANY_COUNTRY] =
        this.locationService.getCountryByIsoCode(
          savedData[this.field.COMPANY_COUNTRY]
        )?.name || '';

      savedData[this.field.COMPANY_PROVINCE] =
        this.locationService.getStateByIsoCode(
          savedData[this.field.COMPANY_PROVINCE]
        )?.name || '';

      this.createCompanyInformationData.set({
        [this.field.COMPANY_NAME]: savedData[this.field.COMPANY_NAME],
        [this.field.COMPANY_COUNTRY]: savedData[this.field.COMPANY_COUNTRY],
        [this.field.COMPANY_STREET]: savedData[this.field.COMPANY_STREET],
        [this.field.COMPANY_PROVINCE]: savedData[this.field.COMPANY_PROVINCE],
        [this.field.COMPANY_CITY]: savedData[this.field.COMPANY_CITY],
        [this.field.COMPANY_POSTAL_CODE]:
          savedData[this.field.COMPANY_POSTAL_CODE],
        [this.field.COMPANY_EMAIL]: savedData[this.field.COMPANY_EMAIL],
        [this.field.COMPANY_PHONE_NUMBER]:
          savedData[this.field.COMPANY_PHONE_NUMBER],
        [this.field.COMPANY_BILLING_EMAIL]:
          savedData[this.field.COMPANY_BILLING_EMAIL],
        [this.field.COMPANY_LOGO_TITLE]:
          this.companyService.companyLogoTemp()?.[
            MediaField.TITLE
          ] as string,
        [this.field.COMPANY_LOGO_SIZE]: Number(
          this.companyService.companyLogoTemp()?.[MediaField.SIZE]
        ),
        [this.field.COMPANY_LOGO_URL]: '',
      });
      this.informationData.set(getInformationConfirmation(savedData));
    }
  }

  private loadSavedAdminFormData() {
    const savedInformationData =
      this.storageService.sessionStorageGet<FormCompanyInformationInterface>(
        SessionStorageKeyEnum.COMPANY_INFORMATION_FORM
      );

    const savedAdminData =
      this.storageService.sessionStorageGet<FormCompanyAdminInterface>(
        SessionStorageKeyEnum.COMPANY_ADMIN_FORM
      );

    if (savedInformationData && savedAdminData) {
      this.createCompanyAdminData.set({
        [this.field.ADMIN_FIRSTNAME]:
          savedAdminData[this.field.ADMIN_FIRSTNAME],
        [this.field.ADMIN_LASTNAME]: savedAdminData[this.field.ADMIN_LASTNAME],
        [this.field.ADMIN_EMAIL]: savedAdminData[this.field.ADMIN_EMAIL],
        [this.field.ADMIN_GENDER]: savedAdminData[this.field.ADMIN_GENDER],
        [this.field.ADMIN_PHONE_NUMBER]:
          savedAdminData[this.field.ADMIN_PHONE_NUMBER],
        [this.field.ADMIN_ROLE]: 'ADMINS',
        [this.field.ADMIN_ADDRESS]: {
          [this.field.COMPANY_COUNTRY]:
            savedInformationData[this.field.COMPANY_COUNTRY],
          [this.field.COMPANY_STREET]:
            savedInformationData[this.field.COMPANY_STREET],
          [this.field.COMPANY_PROVINCE]:
            savedInformationData[this.field.COMPANY_PROVINCE],
          [this.field.COMPANY_CITY]:
            savedInformationData[this.field.COMPANY_CITY],
          [this.field.COMPANY_POSTAL_CODE]:
            savedInformationData[this.field.COMPANY_POSTAL_CODE],
        },
      });
      this.adminData.set(getAdminConfirmation(savedAdminData));
    }
  }

  private loadSavedDepartmentFormData() {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyDepartmentInterface>(
        SessionStorageKeyEnum.COMPANY_DEPARTMENT_FORM
      );

    if (savedData) {
      this.createCompanyDepartmentData.set({
        [this.field.DEPARTMENT_LIST]: savedData[this.field.DEPARTMENT_LIST],
      });
      this.departmentData.set(savedData[this.field.DEPARTMENT_LIST]);
    }
  }

  private loadSavedProductFormData() {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyProductInterface>(
        SessionStorageKeyEnum.COMPANY_PRODUCT_FORM
      );

    if (savedData) {
      this.createCompanyProductData.set({
        [this.field.PRODUCT_ID]: savedData[this.field.PRODUCT_ID],
        [this.field.PRODUCT_NAME]: 'Boost',
        [this.field.PRODUCT_BILLING_PERIOD]:
          savedData[this.field.PRODUCT_BILLING_PERIOD],
        [this.field.PRODUCT_SEATS]: Number(savedData[this.field.PRODUCT_SEATS]),
        [this.field.LICENSE_TYPE]: 'paid',
      });
      this.productData.set(getProductConfirmation(savedData));
    }
  }

  private openProgressDialog() {
    this.dialogRef = this.dialogService.openWithCompRef(
      ProgressDialogComponent,
      {
        title: 'Create Your Company',
        desc: 'Please wait while your content is being securely uploaded. This may take a moment',
      },
      true,
      true
    );
  }
}
