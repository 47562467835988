import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthLayoutComponent, AdminLayoutComponent } from '@layouts';
import { authGuard, userActiveGuard } from '@guards';
import { ADMIN_ROUTES, SUPER_ADMIN_ROUTES, AUTH_ROUTES } from '@routes';

const routes: Routes = [
  {
    path: 'admin',
    // canActivate: [authGuard],
    component: AdminLayoutComponent,
    loadChildren: () => ADMIN_ROUTES,
  },
  {
    path: 'superadmin',
    component: AdminLayoutComponent,
    loadChildren: () => SUPER_ADMIN_ROUTES,
  },
  {
    path: 'auth',
    canActivate: [userActiveGuard],
    component: AuthLayoutComponent,
    loadChildren: () => AUTH_ROUTES,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
