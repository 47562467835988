import { Amplify } from '@aws-amplify/core';
import { StorageAction } from '@aws-amplify/core/internals/utils';
import { calculateContentMd5 } from '../../utils/md5.mjs';
import { resolveS3ConfigAndInput } from '../../utils/resolveS3ConfigAndInput.mjs';
import '../../../../errors/types/validation.mjs';
import '../../../../utils/logger.mjs';
import { validateStorageOperationInput } from '../../utils/validateStorageOperationInput.mjs';
import { STORAGE_INPUT_KEY } from '../../utils/constants.mjs';
import '../../utils/client/base.mjs';
import '../../utils/client/getObject.mjs';
import '../../utils/client/listObjectsV2.mjs';
import { putObject } from '../../utils/client/putObject.mjs';
import '../../utils/client/createMultipartUpload.mjs';
import '../../utils/client/uploadPart.mjs';
import '../../utils/client/completeMultipartUpload.mjs';
import '../../utils/client/listParts.mjs';
import '../../utils/client/abortMultipartUpload.mjs';
import '../../utils/client/copyObject.mjs';
import '../../utils/client/headObject.mjs';
import '../../utils/client/deleteObject.mjs';
import { getStorageUserAgentValue } from '../../utils/userAgent.mjs';
import { constructContentDisposition } from '../../utils/constructContentDisposition.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Get a function the returns a promise to call putObject API to S3.
 *
 * @internal
 */
const putObjectJob = (uploadDataInput, abortSignal, totalLength) => async () => {
  const {
    options: uploadDataOptions,
    data
  } = uploadDataInput;
  const {
    bucket,
    keyPrefix,
    s3Config,
    isObjectLockEnabled,
    identityId
  } = await resolveS3ConfigAndInput(Amplify, uploadDataOptions);
  const {
    inputType,
    objectKey
  } = validateStorageOperationInput(uploadDataInput, identityId);
  const finalKey = inputType === STORAGE_INPUT_KEY ? keyPrefix + objectKey : objectKey;
  const {
    contentDisposition,
    contentEncoding,
    contentType = 'application/octet-stream',
    metadata,
    onProgress
  } = uploadDataOptions ?? {};
  const {
    ETag: eTag,
    VersionId: versionId
  } = await putObject({
    ...s3Config,
    abortSignal,
    onUploadProgress: onProgress,
    userAgentValue: getStorageUserAgentValue(StorageAction.UploadData)
  }, {
    Bucket: bucket,
    Key: finalKey,
    Body: data,
    ContentType: contentType,
    ContentDisposition: constructContentDisposition(contentDisposition),
    ContentEncoding: contentEncoding,
    Metadata: metadata,
    ContentMD5: isObjectLockEnabled ? await calculateContentMd5(data) : undefined
  });
  const result = {
    eTag,
    versionId,
    contentType,
    metadata,
    size: totalLength
  };
  return inputType === STORAGE_INPUT_KEY ? {
    key: objectKey,
    ...result
  } : {
    path: objectKey,
    ...result
  };
};
export { putObjectJob };
