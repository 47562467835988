import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MobileService } from '@app/core/services';
import { AppSwitchComponent } from '@app/shared/components/app-switch/app-switch.component';
import { BadgeComponent } from '@app/shared/components/badge/badge.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    BadgeComponent,
    FontAwesomeModule,
    AppSwitchComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  constructor(public mobileService: MobileService) {}
}
