import { CompanyField } from "./enums/company.field";

import { FormControl, FormGroup } from '@angular/forms';

import { MediaInterface } from '@interfaces';
import { Validators } from '@app/shared/validators/validator.class';
import { GenderType, BillingPeriodType, LicenseStatus } from '@types';

const field = CompanyField;

export const CompanyInformationFormField = new FormGroup({
  [field.COMPANY_NAME]: new FormControl<string | null>(null, {
    validators: [Validators.required],
    asyncValidators: [Validators.companyNameAvailability()],
  }),
  [field.COMPANY_COUNTRY]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.COMPANY_STREET]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.COMPANY_PROVINCE]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.COMPANY_CITY]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.COMPANY_POSTAL_CODE]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.COMPANY_EMAIL]: new FormControl<string | null>(null, [
    Validators.required,
    Validators.email,
  ]),
  [field.COMPANY_PHONE_NUMBER]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.COMPANY_BILLING_EMAIL]: new FormControl<string | null>(null, [
    Validators.required,
    Validators.email,
  ]),
  [field.COMPANY_LOGO_TEMP]: new FormControl<MediaInterface | null>(null),
});

export const CompanyAdminFormField = new FormGroup({
  [field.ADMIN_FIRSTNAME]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.ADMIN_LASTNAME]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.ADMIN_EMAIL]: new FormControl<string | null>(null, {
    validators: [Validators.required, Validators.email],
    asyncValidators: [Validators.userEmailAvailability()],
  }),
  [field.ADMIN_GENDER]: new FormControl<GenderType | null>(null, [
    Validators.required,
  ]),
  [field.ADMIN_PHONE_NUMBER]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
});

export const CompanyDepartmentFormField = new FormGroup({
  [field.DEPARTMENT_LIST]: new FormControl<string[]>([]),
});

export const CompanyProductFormField = new FormGroup({
  [field.PRODUCT_ID]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [field.PRODUCT_BILLING_PERIOD]: new FormControl<BillingPeriodType>(
    'monthly',
  ),
  [field.PRODUCT_SEATS]: new FormControl<number>(1, [
    Validators.seatsMinimum(),
  ]),
});

export const CompanyFilterFormField = new FormGroup({
  [field.LICENSE_STATUS]: new FormControl<LicenseStatus | null>(null)
});
