import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { orderBy } from 'lodash';

import { FormControl } from '@angular/forms';
import { OptionInterface } from '@interfaces';
import { InputComponent } from '../../inputs';
import { OptionLabelComponent } from '../../label';
import { ButtonDirective, LazyloadDirective } from '@directives';

@Component({
  selector: 'app-select-panel',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    LazyloadDirective,
    OptionLabelComponent,
    ButtonDirective
  ],
  templateUrl: './select-panel.component.html',
  styleUrls: ['./select-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPanelComponent implements OnInit, OnDestroy {
  @Input()
  public control = new FormControl();

  @Input()
  public options?: Array<OptionInterface>;

  @Input()
  public filterable?: boolean;

  @Input()
  public filterPlaceholder = '';

  @Input()
  public filterThreshold = 7;

  public readonly onSelect = output<OptionInterface>();


  public readonly queryControl = new FormControl<string>('');

  public filteredOptions: Array<OptionInterface> = [];

  public loadedOptions: Array<OptionInterface> = [];

  private subs: Array<Subscription> = [];

  public ngOnInit(): void {
    this.filterOptions();
    this.subs.push(
      this.queryControl.valueChanges.subscribe(() => {
        this.filterOptions();
      })
    );
  }

  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public onScroll(event: Array<OptionInterface>): void {
    this.loadedOptions = event;
  }

  public OnSelect(option: OptionInterface){
    this.control.setValue(option.value);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.onSelect.emit(option);
  }

  public resetQuery(): void {
    this.queryControl.setValue(null);
  }

  private filterOptions(): void {
    const options = this.queryControl.value
      ? this.options?.filter((o) => {
          const nameFiltered = o.name
            .toLowerCase()
            .includes((this.queryControl.value as string).toLowerCase());
          return nameFiltered;
        })
      : this.options;

    this.filteredOptions = this.orderOptions(options);
  }

  private orderOptions(
    options?: Array<OptionInterface> | null
  ): Array<OptionInterface> {
    return orderBy(options);
    // return orderBy(options, (o) => o.name);
  }
}
