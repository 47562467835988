import { Component, HostBinding, Input, input } from '@angular/core';
import { MoovdLoading } from '../loading/loading.component';
import { MoovdIcon } from '../icon/icon.component';
import * as i0 from "@angular/core";
const _c0 = ["*"];
function MoovdLabel_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "moovd-icon", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.iconPosition());
    i0.ɵɵproperty("icon", ctx_r0.icon);
  }
}
function MoovdLabel_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "moovd-loading");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.iconPosition());
  }
}
export class MoovdLabel {
  icon;
  iconOnly = input(false);
  iconPosition = input('left');
  loading = input(false);
  underline = input(false);
  gap = 0.25;
  static ɵfac = function MoovdLabel_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MoovdLabel)();
  };
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: MoovdLabel,
    selectors: [["moovd-label"]],
    hostVars: 2,
    hostBindings: function MoovdLabel_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵstyleProp("gap", ctx.gap, "rem");
      }
    },
    inputs: {
      icon: "icon",
      iconOnly: [1, "iconOnly"],
      iconPosition: [1, "iconPosition"],
      loading: [1, "loading"],
      underline: [1, "underline"],
      gap: "gap"
    },
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    ngContentSelectors: _c0,
    decls: 4,
    vars: 3,
    consts: [[3, "icon", "class"], [3, "class"], [3, "icon"]],
    template: function MoovdLabel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵtemplate(0, MoovdLabel_Conditional_0_Template, 1, 3, "moovd-icon", 0)(1, MoovdLabel_Conditional_1_Template, 1, 2, "moovd-loading", 1);
        i0.ɵɵelementStart(2, "span");
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵconditional(ctx.icon && !ctx.loading() ? 0 : ctx.loading() ? 1 : -1);
        i0.ɵɵadvance(2);
        i0.ɵɵclassProp("underline", ctx.underline());
      }
    },
    dependencies: [MoovdLoading, MoovdIcon],
    styles: ["[_nghost-%COMP%]{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;overflow:hidden}moovd-icon[_ngcontent-%COMP%], moovd-loading[_ngcontent-%COMP%]{flex-shrink:0}moovd-icon.right[_ngcontent-%COMP%], moovd-loading.right[_ngcontent-%COMP%]{order:100}span[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;text-transform:capitalize}span.underline[_ngcontent-%COMP%]{text-decoration:underline}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MoovdLabel, [{
    type: Component,
    args: [{
      selector: 'moovd-label',
      standalone: true,
      imports: [MoovdLoading, MoovdIcon],
      template: "@if (icon && !loading()) {\n    <moovd-icon [icon]=\"icon\" [class]=\"iconPosition()\"/>\n}@else if (loading()) {\n    <moovd-loading [class]=\"iconPosition()\"/>\n} \n\n\n<span [class.underline]=\"underline()\">\n    <ng-content></ng-content>\n</span>",
      styles: [":host{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;overflow:hidden}moovd-icon,moovd-loading{flex-shrink:0}moovd-icon.right,moovd-loading.right{order:100}span{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;text-transform:capitalize}span.underline{text-decoration:underline}\n"]
    }]
  }], null, {
    icon: [{
      type: Input
    }],
    gap: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['style.gap.rem']
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(MoovdLabel, {
    className: "MoovdLabel"
  });
})();
