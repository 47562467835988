import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-verify',
  standalone: true,
  imports: [],
  templateUrl: './auth-verify.component.html',
  styleUrl: './auth-verify.component.scss'
})
export class AuthVerifyComponent {

}
