<header *ngIf="filterable">
  <app-input
    [control]="queryControl"
    [preventEnter]="true"
    placeholder="Search..."
    [autofocus]="true"
    class="query-input"
    size="sm"
  ></app-input>
</header>

<main #main [ngClass]="{h10: filteredOptions.length > 0 && filteredOptions.length > 3}">
  @for (option of filteredOptions; track $index) {
  <div class="radio-button-wrap">
    <div class="radio-button-circle"></div>
  </div>
  <button
    type="button"
    [title]="option.name"
    class="option"
    [ngClass]="{ choosed: option.value === control.value }"
    (click)="OnSelect(option)"
  >
    <app-option-label [option]="option">
      <div class="radio-button-wrap">
        <div class="radio-button-circle"></div>
      </div>
    </app-option-label>
  </button>

  }

  <ng-container *ngIf="filteredOptions.length === 0">
    <p class="no-results">No results</p>
  </ng-container>
</main>
