export enum MediaField {
  TITLE = 'title',
  URL = 'url',
  SIZE = 'size',
  DURATION = 'duration',
  VALUE = 'value',
}

export interface MediaInterface {
  [MediaField.TITLE]: string;
  [MediaField.SIZE]?: number;
  [MediaField.VALUE]?: any;
  [MediaField.URL]?: string;
  [MediaField.DURATION]?: string;
}
