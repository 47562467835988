export enum LocalStorageKeyEnum {
  LOCALE = 'locale',
}

export enum SessionStorageKeyEnum {
  COMPANY_INFORMATION_FORM = 'company-information-form-data',
  COMPANY_ADMIN_FORM = 'company-admin-data',
  COMPANY_DEPARTMENT_FORM = 'company-department-data',
  COMPANY_PRODUCT_FORM = 'company-product-data',
}
