import { Routes } from '@angular/router';
import { CompanyFormComponent } from '@app/feature/company/pages/company-form/company-form.component';
import { CompanyListComponent } from '@app/feature/company/pages/company-list/company-list.component';
import { COMPANY_FORM_ROUTES } from './features/company/company-form.routes';

export const SUPER_ADMIN_ROUTES: Routes = [
  {
    path: 'companies',
    children: [
      {
        path: 'list',
        component: CompanyListComponent,
      },
      {
        path: 'create',
        component: CompanyFormComponent,
        loadChildren: () => COMPANY_FORM_ROUTES,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: '**',
        redirectTo: 'list',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'companies',
  },
  {
    path: '**',
    redirectTo: 'companies',
  },
];
