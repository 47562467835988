import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, DestroyRef, inject, Inject, signal } from '@angular/core';
import { CardComponent } from '@components';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CompanyService } from '@services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DetailOptionInterface, MediaField } from '@interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { ImageComponent } from '@app/shared/components/media/image/image.component';

import {
  getInformationConfirmation,
  getAdminConfirmation,
  getProductConfirmation,
} from '../../datas/company-preview.data';
import { CompanyField, getCompanyDetailsByIdResponse } from '../../models';
import { CommonModule } from '@angular/common';
import { MoovdButton, MoovdLabel, MoovdLoading } from 'moovd-components';

@Component({
  selector: 'app-company-view-dialog',
  standalone: true,
  imports: [
    CardComponent,
    FontAwesomeModule,
    TranslateModule,
    ImageComponent,
    CommonModule,
    MoovdButton,
    MoovdLabel,
    MoovdLoading
  ],
  templateUrl: './company-view-dialog.component.html',
  styleUrl: './company-view-dialog.component.scss',
})
export class CompanyViewDialogComponent {
  public loading = signal<boolean>(false);
  private destroyRef = inject(DestroyRef);

  public informationData = signal<DetailOptionInterface[]>([]);
  public adminData = signal<DetailOptionInterface[]>([]);
  public departmentData = signal<string[]>([]);
  public productData = signal<DetailOptionInterface[]>([]);
  
  public logoUrl = signal<string>('');
  public logoTitle = signal<string>('');
  public adminAddress = signal<string>('');

  public field = CompanyField;
  public mediaField = MediaField;

  constructor(
    @Inject(DIALOG_DATA)
    public data: string,
    private dialogRef: DialogRef<boolean, CompanyViewDialogComponent>,
    private companyService: CompanyService,
  ) {}

  ngOnInit() {
    this.load(this.data);
  }

  public close() {
    this.dialogRef.close();
  }

  

  private load(licenseId: string) {
    this.loading.set(true);
    this.companyService
      .getCompanyDetail(licenseId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.loading.set(false);
          this.loadInformationData(res);
          this.loadAdminData(res);
          this.loadDepartmentData(res);
          this.loadProductData(res);
          this.setAdminAddress(res);
        },
        error: (err) => {
          this.loading.set(false);
          console.log('err', err);
        },
      });
  }

  private setAdminAddress(data: getCompanyDetailsByIdResponse):void{
    this.adminAddress.set(
      (
        data.companyAddress.streetAddress.toUpperCase() +
        ", " +
        data.companyAddress.city.toUpperCase() +
        ", " +
        data.companyAddress.province.toUpperCase() +
        ", " +
        data.companyAddress.country.toUpperCase() +
        ", " +
        data.companyAddress.postalCode.toUpperCase()
      )
    )
  }

  private loadInformationData(data: getCompanyDetailsByIdResponse) {
    this.informationData.set(
      getInformationConfirmation({
        [this.field.COMPANY_NAME]: data.companyName,
        [this.field.COMPANY_COUNTRY]: data.companyAddress.country,
        [this.field.COMPANY_STREET]: data.companyAddress.streetAddress,
        [this.field.COMPANY_PROVINCE]: data.companyAddress.province,
        [this.field.COMPANY_CITY]: data.companyAddress.city,
        [this.field.COMPANY_POSTAL_CODE]: data.companyAddress.postalCode,
        [this.field.COMPANY_EMAIL]: data.companyEmail,
        [this.field.COMPANY_PHONE_NUMBER]: data.phoneNumber,
        [this.field.COMPANY_BILLING_EMAIL]: data.billingEmail,
        [this.field.COMPANY_LOGO_TEMP]: {
          [this.mediaField.TITLE]: data.companyLogo[MediaField.TITLE],
          [this.mediaField.URL]: data.companyLogo[MediaField.URL],
          [this.mediaField.SIZE]: data.companyLogo[MediaField.SIZE],
        },
      })
    );

    this.logoTitle.set(data.companyLogo[MediaField.TITLE]);
    this.logoUrl.set(data.companyLogo[MediaField.URL] as string);
  }

  private loadAdminData(data: getCompanyDetailsByIdResponse) {
    this.adminData.set(
      getAdminConfirmation({
        [this.field.ADMIN_FIRSTNAME]: data.companyAdmins.firstName,
        [this.field.ADMIN_LASTNAME]: data.companyAdmins.lastName,
        [this.field.ADMIN_EMAIL]: data.companyAdmins.email,
        [this.field.ADMIN_GENDER]: data.companyAdmins.gender,
        [this.field.ADMIN_PHONE_NUMBER]: data.companyAdmins.phoneNumber,
      })
    );
  }

  private loadDepartmentData(data: getCompanyDetailsByIdResponse) {
    const departmentNames = data.departments.map(
      (department) => department.name
    );
    this.departmentData.set(departmentNames);
  }

  private loadProductData(data: getCompanyDetailsByIdResponse){
    this.productData.set(
      getProductConfirmation({
        [this.field.PRODUCT_ID]: data.product.productName,
        [this.field.PRODUCT_BILLING_PERIOD]: data.product.billingPeriod,
        [this.field.PRODUCT_SEATS]: data.product.seats,
      })
    )
  }
}
