@if (title()) {
<h5 class="text-capitalize">{{ title() }}</h5>
}

<div class="multiple-chip mb-10">
  @for (option of options(); track $index) {
  <div (click)="setValue(option)">
    <app-chip [color]="isChoosed(option)">{{ option.name }}</app-chip>
  </div>
  }
</div>
