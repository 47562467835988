import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from '@components';
import { CompanyService } from '@services';

@Component({
  selector: 'app-company-form',
  standalone: true,
  imports: [RouterModule, BreadcrumbComponent, CommonModule, TranslateModule],
  templateUrl: './company-form.component.html',
  styleUrl: './company-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyFormComponent {
  public route = inject(Router);

  public activeStep = signal<1 | 2 | 3 | 4 | 5>(1);

  public companyManagementStep = computed(() => {
    return [
      {
        no: 1,
        title: 'text.input-company-information',
        isActive: this.companyService.formStep() >= 0,
        isLastStep: false,
      },
      {
        no: 2,
        title: 'text.input-company-admin',
        isActive: this.companyService.formStep() >= 1,
        isLastStep: false,
      },
      {
        no: 3,
        title: 'text.input-department',
        isActive: this.companyService.formStep() >= 2,
        isLastStep: false,
      },
      {
        no: 4,
        title: 'text.product-and-payment',
        isActive: this.companyService.formStep() >= 3,
        isLastStep: false,
      },
      {
        no: 5,
        title: 'text.confirmation',
        isActive: this.companyService.formStep() >= 4,
        isLastStep: true,
      },
    ];
  });

  constructor(private companyService: CompanyService) {
    effect(() => {
      this.companyService.formStep();
    });
  }

  ngOnInit() {
    const step = this.route.url.split('/').pop();
    this.updateStep(step);
  }

  private updateStep(step: any) {
    switch (step) {
      case 'information':
        this.companyService.formStep.set(
          this.companyService.formStepEnum.COMPANY_INFORMATION
        );
        break;
      case 'admin':
        this.companyService.formStep.set(
          this.companyService.formStepEnum.COMPANY_ADMIN
        );
        break;
      case 'department':
        this.companyService.formStep.set(
          this.companyService.formStepEnum.COMPANY_DEPARTMENT
        );
        break;
      case 'product':
        this.companyService.formStep.set(
          this.companyService.formStepEnum.COMPANY_PRODUCT
        );
        break;
      case 'confirmation':
        this.companyService.formStep.set(
          this.companyService.formStepEnum.COMPANY_CONFIRMATION
        );
        break;
    }
  }
}
