import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { MediaService } from '@services';
import { DetailSectionInterface } from '@interfaces';

import { ImageComponent } from '@app/shared/components/media/image/image.component';
import { MoovdIcon } from 'moovd-components';

@Component({
  selector: 'app-company-preview',
  standalone: true,
  imports: [CommonModule, TranslateModule, ImageComponent, MoovdIcon],
  templateUrl: './company-preview.component.html',
  styleUrl: './company-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPreviewComponent {
  public data = input<DetailSectionInterface[]>();

  constructor(public mediaService: MediaService) {}
}
