import { Injectable } from '@angular/core';
import { Country } from '@app/core/models/country.model';
import { countries } from './data/countries-data.geo';

@Injectable({
  providedIn: 'root'
})
export class GeoService {
  public getCountries(): Array<Country> {
    return countries;
  } 

  public getCountryByCode(code: string): Country | undefined {
    return this.getCountries().find((c) => c.code === code);
  }

}
