<section class="form-wrap w-full h-fit">
  <header class="form-header w-full h-fit">
    <h2 class="text-capitalize">
      {{ "form.title.company-product" | translate }}
    </h2>
  </header>
  <app-form [form]="form">
    <div class="grid-1">
      <app-form-field
        [label]="'form.label.company-product' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-single-select-input
          [options]="selectOptionsService.productOptions"
          [control]="form.controls[field.PRODUCT_ID]"
          [placeholder]="'form.placeholder.company-product' | translate"
          [filter]="true"
        />
      </app-form-field>
    </div>

    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.license-type' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-single-select-input
          [disabled]="true"
          [options]="selectOptionsService.licenseOptions"
          [placeholder]="
            form.controls[field.PRODUCT_ID].value !== null
              ? 'WeMind Boost'
              : ('form.placeholder.license-type' | translate)
          "
          [filter]="true"
        />
      </app-form-field>
    </div>

    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.billing-period' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <div class="radio-group">
          <label class="radio-container">
            <input
              type="radio"
              [name]="field.PRODUCT_BILLING_PERIOD"
              value="monthly"
              checked
            />
            <span class="radio-label">{{ "text.monthly" | translate }}</span>
          </label>
          <label
            class="radio-container disabled"
            [title]="'Only the monthly option is available for now.'"
          >
            <!-- <input type="radio" [name]="field.PRODUCT_BILLING_PERIOD" value="yearly"> -->
            <span class="radio-label">{{ "text.yearly" | translate }}</span>
          </label>
        </div>
      </app-form-field>
    </div>

    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.billing-type' | translate"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'text.billed-by-moovd' | translate"
          [disabled]="true"
        />
        <app-input-info-message
          [showMessage]="true"
          [message]="'info-message.billing-type' | translate"
        />
      </app-form-field>
    </div>

    <div class="seats-input w-full mt-12">
      <div class="seats-input__left">
        <app-form-field
          [label]="'form.label.input-seats' | translate"
          [required]="true"
          class="text-capitalize"
        >
          <app-input
            [placeholder]="'form.placeholder.input-seats' | translate"
            [control]="form.controls[field.PRODUCT_SEATS]"
            inputType="number"
          />
        </app-form-field>
      </div>
      <div class="seats-input__right grid-2 gap">
        <app-form-field
          [label]="'text.amount' | translate"
          class="text-capitalize"
        >
          <p class="amount-text">USD 0.50</p>
        </app-form-field>
        <app-form-field
          [label]="'text.total-amount' | translate"
          class="text-capitalize"
        >
          <p class="total-amount-text">
            USD {{ totalAmount() >= 0 ? totalAmount() : 0 }}
          </p>
        </app-form-field>
      </div>
    </div>
  </app-form>

  <div class="grid-2 w-full mt-12">
    <div></div>
    <div class="grid-2">
      <moovd-button
        [variant]="'secondary'"
        [stretch]="true"
        [outline]="true"
        (click)="back()"
      >
        <moovd-label>{{ "button.back" | translate }}</moovd-label>
      </moovd-button>
      <moovd-button
        [stretch]="true"
        (click)="submit()"
        [isDisabled]="form.invalid"
      >
        <moovd-label>{{ "button.next" | translate }}</moovd-label>
      </moovd-button>
    </div>
  </div>
</section>
