<div class="content-wrap w-full">
  <div class="content-left">
    @if (label()) {
    <label>
      {{ label() }}
      @if (required()) {
      <span>*</span>
      }
    </label>
    }
  </div>
  <div class="content-right">
    @if(disableToggle()){
      <label class="text-lowecase">
        <input type="checkbox" 
        [checked]="checkbox()"
        (click)="checkbox.set(!checkbox())"
        />
        {{ toggleLabel() }}
      </label>
    }
  </div>
</div>

<ng-content></ng-content>
