import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  inject,
  signal,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FormBase } from '@classes';
import {
  FileInputComponent,
  FormComponent,
  FormFieldComponent,
  InputComponent,
} from '@components';
import {
  StorageService,
  SelectOptionsService,
  CompanyService,
} from '@services';
import { OptionInterface } from '@interfaces';
import { SessionStorageKeyEnum } from '@enums';

import { SingleSelectInputComponent } from '@app/shared/components/inputs/single-select-input/single-select-input.component';
import { PhoneInputComponent } from '@app/shared/components/inputs/phone-input/phone-input.component';
import {
  CompanyField,
  CompanyInformationFormField,
  FormCompanyInformationInterface,
} from '@app/feature/company/models';
import { MoovdButton, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-company-information-form',
  standalone: true,
  imports: [
    CommonModule,
    FormComponent,
    TranslateModule,
    FormFieldComponent,
    SingleSelectInputComponent,
    PhoneInputComponent,
    FileInputComponent,
    InputComponent,
    MoovdButton,
    MoovdLabel
  ],
  templateUrl: './company-information-form.component.html',
  styleUrl: './company-information-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyInformationFormComponent extends FormBase<
  FormCompanyInformationInterface,
  unknown
> {
  private destroyRef = inject(DestroyRef);

  public override form = CompanyInformationFormField;
  public readonly field = CompanyField;
  private formStep = this.companyService.formStepEnum;

  public useCompanyEmail = signal(false);

  public choosedCountry = signal<string | null>(null);
  public choosedState = signal<string | null>(null);

  public readonly countriesOption = this.selectOptionsService.getCountries();

  public statesOption = computed<OptionInterface[]>(() => {
    if (this.choosedCountry() !== null) {
      return this.selectOptionsService.getStates(
        this.choosedCountry() as string
      );
    }
    return [];
  });

  public citiesOption = computed<OptionInterface[]>(() => {
    if (this.choosedCountry() !== null && this.choosedState() !== null) {
      return this.selectOptionsService.getCities(
        this.choosedCountry() as string,
        this.choosedState() as string
      );
    }
    return [];
  });

  constructor(
    private selectOptionsService: SelectOptionsService,
    private storageService: StorageService,
    private companyService: CompanyService,
    private router: Router
  ) {
    super();
    effect(() => {
      if (this.useCompanyEmail()) {
        this.form.controls[this.field.COMPANY_BILLING_EMAIL].setValue(
          this.form.controls[this.field.COMPANY_EMAIL].value
        );
      }
    });
  }

  ngOnInit() {
    this.loadSavedFormData();

    this.form.controls[this.field.COMPANY_COUNTRY].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((countryIsoCode) => {
        this.choosedCountry.set(countryIsoCode);
      });

    this.form.controls[this.field.COMPANY_PROVINCE].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((stateIsoCode) => {
        this.choosedState.set(stateIsoCode);
      });

    this.form.controls[this.field.COMPANY_EMAIL].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((companyEmail) => {
        if (this.useCompanyEmail()) {
          this.form.controls[this.field.COMPANY_BILLING_EMAIL].setValue(
            companyEmail
          );
        }
      });
  }

  public checkCompanyLogo() {
    if (this.form.controls[this.field.COMPANY_LOGO_TEMP].value == null) {
      return true;
    }

    return false;
  }

  protected back(): Promise<boolean> {
    return this.router.navigate(['superadmin/list']);
  }

  protected override onSubmit(
    data: FormCompanyInformationInterface
  ): Observable<unknown> {
    this.storageService.sessionStorageSet(
      SessionStorageKeyEnum.COMPANY_INFORMATION_FORM,
      data
    );
    this.companyService.companyLogoTemp.set(data[this.field.COMPANY_LOGO_TEMP]);
    this.router.navigate(['superadmin/companies/create/admin']);
    this.companyService.formStep.set(this.formStep.COMPANY_ADMIN);
    return of([]);
  }

  private loadSavedFormData(): void {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyInformationInterface>(
        SessionStorageKeyEnum.COMPANY_INFORMATION_FORM
      );

    if (savedData) {
      this.form.setValue(savedData);
      this.choosedCountry.set(savedData[this.field.COMPANY_COUNTRY]);
      this.choosedState.set(savedData[this.field.COMPANY_PROVINCE]);
    }
  }
}
