<app-dialog [disableClose]="data.disableClose" (closeClicked)="close()">
  @if (countdown$) {
    <h6 class="title">
      {{ data.title }} <span>{{ countdown$ | async | date: 'm:ss' }} </span>
    </h6>
  } @else {
    <h6 class="title">{{ data.title }}</h6>
  }

  @if (data.description) {
    <p class="description" [innerHTML]="data.description | sanitize"></p>
  }

  <div class="button-group">
    @if (data.secondaryLabel) {

      <moovd-button
      [variant]="'secondary'"
      [stretch]="true"
      [outline]="true"
      (click)="close()"
      >
        <moovd-label>{{ data.secondaryLabel }}</moovd-label>
      </moovd-button>
    }

    <moovd-button
      [stretch]="true"
      (click)="delete_data()"
      >
        <moovd-label>{{ data.primaryLabel }}</moovd-label>
      </moovd-button>
  </div>
</app-dialog>
