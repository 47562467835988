<section class="form-wrap w-full h-fit">
  <header class="form-header w-full h-fit">
    <h2 class="text-capitalize">
      {{ "form.title.company-confirmation" | translate }}
    </h2>
  </header>
  <div class="form-content w-full">
    <div class="confirmation-card w-full">
      <div class="confirmation-header w-full">
        <h3 class="text-capitalize">
          {{ "form.title.company-information" | translate }}
        </h3>
        <a href="/admin/companies/create/company-information">
          <moovd-icon [icon]="'saxEdit2Outline'" title="edit"></moovd-icon>
        </a>
      </div>
      <div class="confirmation-content">
        @for (data of informationData(); track $index) {

        <div class="confirmation-content__card">
          <div class="contirmation-content__title">
            <h5 class="text-capitalize">{{ data.title | translate }}</h5>
          </div>
          <div class="confirmation-content__value">
            @if(data.title === 'form.label.company-logo'){
            <div class="logo-wrap">
              @if( this.mediaService.mediaSrc() ){
              <img [src]="this.mediaService.mediaSrc()" alt="logo" />
              }
            </div>
            }@else{
            <p>{{ data.value }}</p>
            }
          </div>
        </div>
        }
      </div>
    </div>

    <div class="confirmation-card w-full">
      <div class="confirmation-header w-full">
        <h3 class="text-capitalize">
          {{ "form.title.company-admin" | translate }}
        </h3>
        <a href="/admin/companies/create/company-admin">
          <moovd-icon [icon]="'saxEdit2Outline'" title="edit"></moovd-icon>
        </a>
      </div>
      <div class="confirmation-content">
        @for (data of adminData(); track $index) {
        <div
          class="confirmation-content__card"
          [ngClass]="{ gridAddress: data.title === 'form.label.address' }"
        >
          <div class="contirmation-content__title">
            <h5 class="text-capitalize">{{ data.title | translate }}</h5>
          </div>
          <div class="confirmation-content__value">
            <p>
              {{
                data.title === "form.label.address"
                  ? getAdminAddress()
                  : data.value
              }}
            </p>
          </div>
        </div>
        }
      </div>
    </div>

    <div class="confirmation-card w-full">
      <div class="confirmation-header w-full">
        <h3 class="text-capitalize">
          {{ "form.title.company-department" | translate }}
        </h3>
        <a href="/admin/companies/create/company-department">
          <moovd-icon [icon]="'saxEdit2Outline'" title="edit"></moovd-icon>
        </a>
      </div>
      <div class="confirmation-content">
        @for (data of departmentData(); track $index) {
        <div class="confirmation-content__card">
          <div class="contirmation-content__title">
            <h5 class="text-capitalize">{{ $index + 1 }}. &nbsp; {{ data }}</h5>
          </div>
        </div>
        }
      </div>
    </div>

    <div class="confirmation-card w-full">
      <div class="confirmation-header w-full">
        <h3 class="text-capitalize">
          {{ "text.product-and-payment" | translate }}
        </h3>
        <a href="/admin/companies/create/product-and-payment">
          <moovd-icon [icon]="'saxEdit2Outline'" title="edit"></moovd-icon>
        </a>
      </div>
      <div class="confirmation-content">
        @for (data of productData(); track $index) {
        <div class="confirmation-content__card">
          <div class="contirmation-content__title">
            <h5 class="text-capitalize">{{ data.title | translate }}</h5>
          </div>
          <div class="confirmation-content__value">
            <p>{{ data.value }}</p>
          </div>
        </div>
        }
      </div>
    </div>
  </div>

  <div class="grid-2 w-full mt-16">
    <div></div>
    <div class="grid-2">
      <moovd-button
        [variant]="'secondary'"
        [stretch]="true"
        [outline]="true"
        (click)="back()"
      >
        <moovd-label>
          {{ "button.back" | translate }}
        </moovd-label>
      </moovd-button>

      <moovd-button
        [stretch]="true"
        (click)="submit()"
      >
        <moovd-label>
          {{ "button.save-and-activate" | translate }}
        </moovd-label>
      </moovd-button>
    </div>
  </div>
</section>
