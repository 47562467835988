import { Injectable, signal } from '@angular/core';
import { MediaInterface } from '@interfaces';
import { getUrl } from 'aws-amplify/storage';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  public mediaSrc = signal<string | null>(null);
  public companyLogo = signal<MediaInterface | null>(null);
  public file = signal<File | null>(null);

  constructor() {}

  public getMediaUrl(path: string): Observable<string> {
    return from(
      getUrl({
        path: path,
      })
    ).pipe(
      map((res) => {
        return res.url.href;
      })
    );
  }
}
