<div class="container">
  <app-card>
    <div class="container__title w-full">
      <h2 class="text-capitalize">{{ "title.company-details" | translate }}</h2>
    </div>
    <div class="container__content w-full">
      @if (loading()) {
      <div class="content-loading">
        <moovd-loading [size]="'lg'"/>
      </div>
      }@else {
      <div class="content-detail h-full">
        <div class="detail">
          <header class="detail__header">
            <h3 class="text-capitalize">
              {{ "form.title.company-information" | translate }}
            </h3>
          </header>
          <div class="detail__content">
            @for (data of informationData(); track $index) {
            <div class="content">
              <h5 class="text-capitalize">{{ data.title | translate }}</h5>
              <div class="content__value">
                @if(data.title === 'form.label.company-logo'){
                <div class="logo">
                  <app-image
                    [path]="this.logoUrl()"
                    [title]="this.logoTitle()"
                  ></app-image>
                </div>
                }@else {
                <p>{{ data.value }}</p>
                }
              </div>
            </div>
            }
          </div>
        </div>

        <div class="detail">
          <header class="detail__header">
            <h3 class="text-capitalize">
              {{ "form.title.company-admin" | translate }}
            </h3>
          </header>
          <div class="detail__content">
            @for (data of adminData(); track $index) {
            <div
              class="content"
              [ngClass]="{ gridAddress: data.title === 'form.label.address' }"
            >
              <h5 class="text-capitalize">{{ data.title | translate }}</h5>
              <div class="content__value">
                <p>
                  {{
                    data.title === "form.label.address"
                      ? adminAddress()
                      : data.value
                  }}
                </p>
              </div>
            </div>
            }
          </div>
        </div>

        <div class="detail">
          <header class="detail__header">
            <h3 class="text-capitalize">
              {{ "form.title.company-department" | translate }}
            </h3>
          </header>
          <div class="detail__content">
            @for (data of departmentData(); track $index) {
            <div class="content">
              <h5 class="text-capitalize">
                {{ $index + 1 }}. &nbsp; {{ data }}
              </h5>
            </div>
            }
          </div>
        </div>

        <div class="detail">
          <header class="detail__header">
            <h3 class="text-capitalize">
              {{ "text.product-and-payment" | translate }}
            </h3>
          </header>
          <div class="detail__content">
            @for (data of productData(); track $index) {
            <div class="content">
              <h5 class="text-capitalize">{{ data.title | translate }}</h5>
              <div class="content__value">
                <p>
                  {{ data.value }}
                </p>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      }
    </div>
    <div class="container__button w-full mb-8">
      <moovd-button [stretch]="true" [outline]="true" (click)="close()">
        <moovd-label>{{ "button.close" | translate }} </moovd-label>
      </moovd-button>
    </div>
  </app-card>
</div>
