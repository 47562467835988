@if (title()) {
<h5 class="text-capitalize">{{ title() }}</h5>
}
<div class="radio mb-10">
  @for (option of options(); track $index) {
  <label>
    <input
      type="radio"
      [checked]="activeRadio() === option.value"
      (click)="setRadio(option.value)"
    />
    <span class="custom-radio"></span>
    {{ option.name }}
  </label>
  }
</div>
