export const genderOptions = [
  {
    name: 'Male',
    value: 'male',
  },
  {
    name: 'Female',
    value: 'female',
  },
  {
    name: 'Non Binary',
    value: 'non_binary',
  },
  {
    name: 'Prefer Not To Say',
    value: 'prefer_not_to_say',
  },
];

export const productOptions = [
  {
    name: 'Boost',
    value: 'prod_R89PJXYk3TUpWi',
  }
]

export const licenseOptions = [
  {
    name: 'WeMind Boost',
    value: 'prod_R89PJXYk3TUpWi',
  }
]
