import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MoovdButton, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MoovdButton,
    MoovdLabel,
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  public title = input<string>();

  public buttonText = input<string>();
  public readonly buttonAction = output<void>();

  public secondaryButtonText = input<string>();
  public readonly secondaryButtonAction = output<void>();

  public buttonActionVoid() {
    this.buttonAction.emit();
  }

  public secondaryButtonActionVoid() {
    this.secondaryButtonAction.emit();
  }
}
