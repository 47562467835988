import { parseMetadata } from '@aws-amplify/core/internals/aws-client-utils';
import { AmplifyUrl, AmplifyUrlSearchParams } from '@aws-amplify/core/internals/utils';
import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { defaultConfig } from './base.mjs';
import { parseXmlError, parseXmlBody } from './utils/parsePayload.mjs';
import { s3TransferHandler } from './runtime/s3TransferHandler/fetch.mjs';
import 'fast-xml-parser';
import './runtime/s3TransferHandler/xhr.mjs';
import 'buffer';
import { buildStorageServiceError, map, emptyArrayGuard, deserializeNumber } from './utils/deserializeHelpers.mjs';
import { validateS3RequiredParameter, serializePathnameObjectKey } from './utils/serializeHelpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const listPartsSerializer = async (input, endpoint) => {
  const headers = {};
  const url = new AmplifyUrl(endpoint.url.toString());
  validateS3RequiredParameter(!!input.Key, 'Key');
  url.pathname = serializePathnameObjectKey(url, input.Key);
  validateS3RequiredParameter(!!input.UploadId, 'UploadId');
  url.search = new AmplifyUrlSearchParams({
    uploadId: input.UploadId
  }).toString();
  return {
    method: 'GET',
    headers,
    url
  };
};
const listPartsDeserializer = async response => {
  if (response.statusCode >= 300) {
    const error = await parseXmlError(response);
    throw buildStorageServiceError(error, response.statusCode);
  } else {
    const parsed = await parseXmlBody(response);
    const contents = map(parsed, {
      UploadId: 'UploadId',
      Parts: ['Part', value => emptyArrayGuard(value, deserializeCompletedPartList)]
    });
    return {
      $metadata: parseMetadata(response),
      ...contents
    };
  }
};
const deserializeCompletedPartList = input => input.map(item => map(item, {
  PartNumber: ['PartNumber', deserializeNumber],
  ETag: 'ETag',
  Size: ['Size', deserializeNumber]
}));
const listParts = composeServiceApi(s3TransferHandler, listPartsSerializer, listPartsDeserializer, {
  ...defaultConfig,
  responseType: 'text'
});
export { listParts };
