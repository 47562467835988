import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import {
  FormComponent,
  FormFieldComponent,
  InputComponent,
  InputInfoMessageComponent,
} from '@components';
import { FormBase } from '@classes';
import { SessionStorageKeyEnum } from '@enums';
import {
  CompanyService,
  SelectOptionsService,
  StorageService,
} from '@services';

import { PhoneInputComponent } from '@app/shared/components/inputs/phone-input/phone-input.component';
import { SingleSelectInputComponent } from '@app/shared/components/inputs/single-select-input/single-select-input.component';
import {
  CompanyField,
  CompanyAdminFormField,
  FormCompanyAdminInterface,
} from '@app/feature/company/models';
import { MoovdButton, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-company-admin-form',
  standalone: true,
  imports: [
    FormComponent,
    FormFieldComponent,
    TranslateModule,
    SingleSelectInputComponent,
    PhoneInputComponent,
    InputInfoMessageComponent,
    InputComponent,
    MoovdButton,
    MoovdLabel
  ],
  templateUrl: './company-admin-form.component.html',
  styleUrl: './company-admin-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyAdminFormComponent extends FormBase<
  FormCompanyAdminInterface,
  unknown
> {
  private route = inject(Router);

  public override form = CompanyAdminFormField;
  public readonly field = CompanyField;

  private formStep = this.companyService.formStepEnum;

  constructor(
    public companyService: CompanyService,
    public selectOptionsService: SelectOptionsService,
    private storageService: StorageService
  ) {
    super();
  }
  ngOnInit() {
    this.loadSavedFormData();
  }

  public async back() {
    await this.route.navigate(['superadmin/companies/create/information']);
    this.companyService.formStep.set(this.formStep.COMPANY_INFORMATION);
  }

  protected override onSubmit(
    data: FormCompanyAdminInterface
  ): Observable<unknown> {
    this.storageService.sessionStorageSet(
      SessionStorageKeyEnum.COMPANY_ADMIN_FORM,
      data
    );
    this.route.navigate(['superadmin/companies/create/department']);
    this.companyService.formStep.set(this.formStep.COMPANY_DEPARTMENT);
    return of([]);
  }

  private loadSavedFormData(): void {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyAdminInterface>(
        SessionStorageKeyEnum.COMPANY_ADMIN_FORM
      );

    if (savedData) {
      this.form.setValue(savedData);
    }
  }
}
