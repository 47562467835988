import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MediaService } from '@services';
import { DialogComponent } from '../dialog/dialog.component';
import { Perform } from '@classes';
import { TranslateModule } from '@ngx-translate/core';
import { MoovdButton, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-media-view-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    DialogComponent,
    MoovdButton,
    MoovdLabel,
  ],
  templateUrl: './media-view-dialog.component.html',
  styleUrl: './media-view-dialog.component.scss',
})
export class MediaViewDialogComponent {
  public mediaPerform = new Perform<string>();

  constructor(
    @Inject(DIALOG_DATA)
    public data: {
      media: string | ArrayBuffer | null;
      mediaType: 'video' | 'image';
    },
    private dialogRef: DialogRef<boolean, MediaViewDialogComponent>,
    private mediaService: MediaService
  ) {}

  private loadMedia(path: string) {
    this.mediaPerform.load(this.mediaService.getMediaUrl(path));
  }

  public close() {
    this.dialogRef.close();
  }
}
