import { Routes } from '@angular/router';
import { CompanyDetailComponent } from '@app/feature/company/pages/company-detail/company-detail.component';
import { CompanySectionFormComponent } from '@app/feature/company/pages/company-section-form/company-section-form.component';
import { COMPANY_FORM_ROUTES } from './features/company/company-form.routes';

export const ADMIN_ROUTES: Routes = [
  {
    path: 'company',
    children: [
      {
        path: '',
        component: CompanyDetailComponent,
      },
      {
        path: 'update',
        component: CompanySectionFormComponent,
        loadChildren: () => COMPANY_FORM_ROUTES,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '',
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'company',
  },
  {
    path: '**',
    redirectTo: 'company',
  },
];
