import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconName, MoovdIcon } from 'moovd-components';

@Component({
  selector: 'app-admin-menu',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MoovdIcon
  ],
  templateUrl: './admin-menu.component.html',
  styleUrl: './admin-menu.component.scss'
})
export class AdminMenuComponent {
  constructor(public route: Router) {}

  public adminMenus = [
    // {
    //   icon: 'saxHome2Outline',
    //   text: 'Dashboard',
    //   url: '/admin/dashboard',
    // },
    {
      icon: 'saxBuildingsOutline' as IconName,
      text: 'Manage Companies',
      url: '/admin/companies',
    },
    {
      icon: 'saxPeopleOutline' as IconName,
      text: 'Manage Users',
      url: '/admin/users',
    },
    // {
    //   icon: 'saxElement4Outline',
    //   text: 'Manage Product',
    //   url: '/admin/products',
    // },
    // {
    //   icon: 'saxReceiptSquareOutline',
    //   text: 'Manage Licence',
    //   url: '/licenses',
    // },
    // {
    //   icon: 'saxDollarSquareOutline',
    //   text: 'Finance',
    //   url: '/finances',
    // },
  ];

  public isActive(route: string) {
    if(this.route.url.includes(route)){
      return true;
    }
    
    return false;
  }

}
