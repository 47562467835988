<div class="company-switch-wrapper">
  <div class="company">
    <img src="assets/images/logos/moovd-logo.png" class="logo" alt="company-logo">
    <h6>MOOVD B.V</h6>
  </div>
  <img src="assets/icons/arrow-separate-vertical.svg" alt="sort arrow icon" class="switch-icon">
</div>

<section>
  <h6>Menu</h6>
  <ng-content></ng-content>
</section>