import {
  Component,
  ElementRef,
  inject,
  model,
  signal,
  viewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputBaseClass, Perform } from '@app/core/classes';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  InputErrorMessageComponent,
  MediaViewDialogComponent,
} from '@components';
import { DialogService, MediaService } from '@services';
import { convertMbToB, getDynamicSize, getFileName } from '@functions';
import { MediaInterface } from '@interfaces';
import { ImageComponent } from '../../media/image/image.component';
import { TranslateModule } from '@ngx-translate/core';
import { MoovdIcon } from 'moovd-components';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [
    FontAwesomeModule,
    ReactiveFormsModule,
    ImageComponent,
    TranslateModule,
    InputErrorMessageComponent,
    MoovdIcon
  ],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss'
})
export class FileInputComponent extends InputBaseClass {
  public dialogService = inject(DialogService);
  public mediaService = inject(MediaService);

  public fileInput = viewChild<ElementRef<HTMLInputElement>>('fileInput');
  public currentData = model<MediaInterface>();

  public readonly MAX_SIZE = 1; // MB
  public readonly MAX_DIMESIONS = {
    width: 512,
    height: 512,
  };
  public readonly ACCESSABLE_FILE_TYPE = '.png,.jpg,.jpeg,.webp';
  public readonly ACCESSABLE_TEXT = `PNG, JPG, JPEG, WEBP Max ${this.MAX_SIZE}MB`;

  public dragging = signal<boolean>(false);
  public progress = signal<number>(20);
  public loading = signal<boolean>(false);
  public error = signal<string | null>(null);
  public mediaPerform = new Perform<string>();

  public ngOnInit() {
    if (this.currentData() != undefined) {
      this.control.setValue(this.currentData());
      this.getFileFromAWS(this.currentData()?.url || '');
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    this.dragging.set(true);
  }

  public onDragLeave() {
    this.dragging.set(false);
  }

  public onFileSelect() {
    this.fileInput()?.nativeElement.click();
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    this.dragging.set(false);
    const droppedFiles = event.dataTransfer?.files;
    if (droppedFiles) {
      this.handleFiles(droppedFiles[0]);
    }
  }

  public onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.handleFiles(input.files[0]);
    } else {
      console.log('No files selected!');
    }
  }

  public getFileSize(fileSize: number | undefined): string {
    if (fileSize) {
      return getDynamicSize(fileSize);
    }

    return '';
  }

  public removeFile(): void {
    this.control.setValue(null);
    this.mediaService.file.set(null);
    this.mediaService.mediaSrc.set(null);
    this.mediaService.companyLogo.set(null);
    this.currentData.set(undefined);
  }

  public openMediaViewDetail(data: {
    media: string | ArrayBuffer | null;
    mediaType: 'image';
  }) {
    this.dialogService.open(MediaViewDialogComponent, data, true, true);
  }

  private handleFiles(file: File) {
    if (file.size > convertMbToB(this.MAX_SIZE)) {
      this.error.set(`File size exceeds ${this.MAX_SIZE} MB`);
      return;
    }

    this.mediaService.file.set(file);
    this.loading.set(true);
    this.previewFile(file);
    const uploadInterval = setInterval(() => {
      this.progress.update((currentData) => (currentData += 10));
      if (this.progress() >= 100) {
        clearInterval(uploadInterval);
        this.loading.set(false);
      }
    }, 50);
  }

  private previewFile(file: File) {
    const img = new Image();
    const reader = new FileReader();
    const reader2 = new FileReader();

    reader.onload = (event: any) => {
      img.src = event.target.result;
      this.mediaService.mediaSrc.set(event.target.result);
    };

    img.onload = () => {
      // if (
      //   img.width > this.MAX_DIMESIONS.width &&
      //   img.height > this.MAX_DIMESIONS.height
      // ) {
      //   this.error.set('maximum dimension is 512x512 px');
      //   this.file.set(null);
      // }
    };

    reader.readAsDataURL(file);

    reader2.readAsArrayBuffer(file);
    reader2.onload = (event) => {
      const formattedName = file.name
        .toLowerCase()
        .replace(/\s+/g, '_')
        .split('.')[0];
      this.mediaService.companyLogo.set({
        title: formattedName + '_' + getFileName(file.type),
        value: event.target?.result,
        size: file.size,
      });
      this.control.setValue(this.mediaService.companyLogo());
    };

    this.error.set(null);
  }

  private getFileFromAWS(path: string) {
    this.mediaPerform.load(this.mediaService.getMediaUrl(path));
  }
}
