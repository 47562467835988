import { Validators as NgValidators } from '@angular/forms';
import { passwordStrengthValidator } from './password.validator';
import { phoneNumberForCountry } from './phone-number-for-country.validator';
import { companyNameAvailability } from './company-name.validator';
import { userEmailAvailability } from './user-email.validator';
import { seatsMinimum } from './seats-minimum.validator';


export class Validators {
  // Map built-in Angular form validators
  public static minLength = NgValidators.minLength;
  public static maxLength = NgValidators.maxLength;
  public static required = NgValidators.required;
  public static requiredTrue = NgValidators.requiredTrue;
  public static email = NgValidators.email;
  public static min = NgValidators.min;
  public static max = NgValidators.max;
  public static passwordStrength = passwordStrengthValidator;
  public static validPhoneNumberForCountry = phoneNumberForCountry;

  // new validator
  public static companyNameAvailability = companyNameAvailability;
  public static userEmailAvailability = userEmailAvailability;
  public static seatsMinimum = seatsMinimum;
}
