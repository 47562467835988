import { isCancelError } from '../../../errors/CanceledError.mjs';
import '../../../errors/types/validation.mjs';
import '@aws-amplify/core/internals/utils';
import { logger } from '../../../utils/logger.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const createCancellableTask = ({
  job,
  onCancel
}) => {
  const state = 'IN_PROGRESS';
  let canceledErrorMessage;
  const cancelableTask = {
    cancel: message => {
      const {
        state: taskState
      } = cancelableTask;
      if (taskState === 'CANCELED' || taskState === 'ERROR' || taskState === 'SUCCESS') {
        logger.debug(`This task cannot be canceled. State: ${taskState}`);
        return;
      }
      cancelableTask.state = 'CANCELED';
      canceledErrorMessage = message;
      onCancel(canceledErrorMessage);
    },
    state
  };
  const wrappedJobPromise = (async () => {
    try {
      const result = await job();
      cancelableTask.state = 'SUCCESS';
      return result;
    } catch (e) {
      if (isCancelError(e)) {
        cancelableTask.state = 'CANCELED';
        e.message = canceledErrorMessage ?? e.message;
      }
      cancelableTask.state = 'ERROR';
      throw e;
    }
  })();
  return Object.assign(cancelableTask, {
    result: wrappedJobPromise
  });
};
const createDownloadTask = createCancellableTask;
const createUploadTask = ({
  job,
  onCancel,
  onResume,
  onPause,
  isMultipartUpload
}) => {
  const cancellableTask = createCancellableTask({
    job,
    onCancel
  });
  const uploadTask = Object.assign(cancellableTask, {
    pause: () => {
      const {
        state
      } = uploadTask;
      if (!isMultipartUpload || state !== 'IN_PROGRESS') {
        logger.debug(`This task cannot be paused. State: ${state}`);
        return;
      }
      // TODO(eslint): remove this linter suppression.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uploadTask.state = 'PAUSED';
      onPause?.();
    },
    resume: () => {
      const {
        state
      } = uploadTask;
      if (!isMultipartUpload || state !== 'PAUSED') {
        logger.debug(`This task cannot be resumed. State: ${state}`);
        return;
      }
      // TODO(eslint): remove this linter suppression.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uploadTask.state = 'IN_PROGRESS';
      onResume?.();
    }
  });
  return uploadTask;
};
export { createDownloadTask, createUploadTask };
