import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  signal,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import {
  ButtonDirective,
  DropdownDirective,
  DropdownComponent,
} from '@directives';
import {
  OptionLabelComponent,
  SelectPanelComponent,
} from '@components';
import { MobileService } from '@services';
import { InputBaseClass } from '@classes';
import { InputSelectAnimation } from '@animations';
import { OptionInterface } from '@interfaces';
import { MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-single-select-input',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    DropdownDirective,
    FontAwesomeModule,
    DropdownComponent,
    TranslateModule,
    SelectPanelComponent,
    OptionLabelComponent,
    MoovdLabel
  ],
  templateUrl: './single-select-input.component.html',
  styleUrls: ['./single-select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InputSelectAnimation.chevronRotate],
})
export class SingleSelectInputComponent extends InputBaseClass {
  public dropdown = viewChild<DropdownDirective>(DropdownDirective);

  public options = input.required<OptionInterface[]>();
  public readonly filter = input<boolean>(false);
  public readonly stretch = input<boolean>(false);
  public disabled = input<boolean>(false);

  public showDropdown = signal(false);

  public panelState = computed(() => {
    return this.showDropdown() ? 'opened' : 'closed';
  });

  public get selectedOption(): OptionInterface | undefined {
    return this.options()?.find((o) => o.value === this.control.value);
  }

  constructor(public mobileService: MobileService) {
    super();
  }

  public onOpen(): void {
    this.showDropdown.set(true);
  }

  public onClose(): void {
    this.showDropdown.set(false);
  }

  public onSelect(): void {
    this.showDropdown.set(false);
    this.dropdown()?.close();
  }
}
