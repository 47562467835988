<section class="form-wrap w-full h-fit">
  <header class="form-header w-full h-fit">
    <h2 class="text-capitalize">
      {{ "form.title.company-department" | translate }}
      <span>({{ "text.optional" | translate }})</span>
    </h2>
  </header>
  <app-form [form]="departmentForm" (formSubmit)="addDepartment()">
    <div class="department">
      <div class="department__input">
        <app-form-field
          [label]="'form.label.department-name' | translate"
          class="text-capitalize"
        >
          <app-input
            [placeholder]="'form.placeholder.department-name' | translate"
            [showErrorMessage]="false"
            [control]="departmentForm.controls.department"
          />
        </app-form-field>
      </div>
      <div class="department__button">
        <moovd-button
        [stretch]="true"
      >
        <moovd-label>{{ "button.save" | translate }}</moovd-label>
      </moovd-button>
      </div>
    </div>
    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.list-department-name' | translate"
        class="text-capitalize"
      >
        <div class="department-list">
          @for (department of departmentList(); track $index) {
          <div class="department-item">
            <p>{{ $index + 1 }}. &nbsp; {{ department }}</p>
            <div
              (click)="removeDepartment(department)"
              class="department-item__icon"
            >
              <moovd-icon [icon]="'saxTrashOutline'" [title]="'delete'"></moovd-icon>
            </div>
          </div>
          }
        </div>
      </app-form-field>
    </div>
  </app-form>

  <div class="grid-2 w-full mt-16">
    <div></div>
    <div class="grid-2">
      <moovd-button
        [variant]="'secondary'"
        [stretch]="true"
        [outline]="true"
        (click)="back()"
      >
        <moovd-label>{{ "button.back" | translate }}</moovd-label>
      </moovd-button>
      <moovd-button
        [stretch]="true"
        (click)="next()"
        [isDisabled]="form.invalid"
      >
        <moovd-label>{{ "button.next" | translate }}</moovd-label>
      </moovd-button>
    </div>
  </div>
</section>
