<section class="card w-full p-12">
  @if (title()) {
  <header class="card-header w-full">
    <h2 class="text-capitalize">
      {{ title() }}
    </h2>
  </header>
  }
  <ng-content></ng-content>

  @if (buttonText()) {
  <footer class="card-footer w-full">
    <div class="grid-2 w-full mt-12 mb-8">
      <div></div>
      <div class="grid-2">
        @if (secondaryButtonText()) {
        <moovd-button
          [variant]="'secondary'"
          [stretch]="true"
          [outline]="true"
          (click)="secondaryButtonActionVoid()"
        >
          <moovd-label>
            {{ secondaryButtonText() }}
          </moovd-label>
        </moovd-button>
        }

        <moovd-button
          [stretch]="true"
          (click)="buttonActionVoid()"
          [ngClass]="{
            'right-button': !secondaryButtonText()
          }"
        >
          <moovd-label>
            {{ buttonText() }}
          </moovd-label>
        </moovd-button>
      </div>
    </div>
  </footer>
  }
</section>
