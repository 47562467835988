<button
  #button
  appButton
  [appDropdown]="dropdown"
  [offsetY]="8"
  class="flag-button"
  [size]="this.size"
  [stretch]="true"
  [shadow]="true"
  [class.square]="square"
  (opened)="onOpened()"
  (closed)="onClosed()"
>
  @if(currentApplication | async; as application){
  <app-avatar class="flag" imgSrc="assets/images/logos/logo.png"></app-avatar>

  @if (!square) {

    <moovd-label class="label language-name">Boost</moovd-label>
  } } @if (!square) {
    <fa-icon icon="chevron-down" class="chevron" [@indicatorRotate]="isOpen" />
  }
</button>

<ng-template #dropdown>
  <app-dropdown>
    @for(application of applications; track application.appName){
      <button
      appButton
      [shadow]="true"
      (click)="changeApp(application.application)"
      >
      <app-avatar class="flag" [imgSrc]="application.appLogo"></app-avatar>
      <moovd-label class="language-name-dropdown">{{
        application.appName
      }}</moovd-label>
    </button>
    }
  </app-dropdown>
</ng-template>
