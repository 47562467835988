export enum AddressField {
  COUNTRY = 'country',
  STREET = 'streetAddress',
  PROVINCE = 'region',
  CITY = 'locality',
  POSTAL_CODE = 'postalCode',
}

export interface AddressInterface {
  [AddressField.COUNTRY]: string;
  [AddressField.PROVINCE]: string;
  [AddressField.CITY]: string;
  [AddressField.STREET]: string;
  [AddressField.POSTAL_CODE]: string;
}
