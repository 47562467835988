import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent, CardComponent } from '@components';

import { CompanyPreviewComponent } from '../../components/company-preview/company-preview.component';
import * as temp from '../../datas/temporary.data';

@Component({
  selector: 'app-company-detail',
  standalone: true,
  imports: [
    TranslateModule,
    BreadcrumbComponent,
    CompanyPreviewComponent,
    CardComponent,
  ],
  templateUrl: './company-detail.component.html',
  styleUrl: './company-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyDetailComponent {
  public readonly data = temp;
}
