import { Component, Input } from '@angular/core';
import { MoovdIcon } from '../icon/icon.component';
import * as i0 from "@angular/core";
export class MoovdLoading {
  size;
  static ɵfac = function MoovdLoading_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MoovdLoading)();
  };
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: MoovdLoading,
    selectors: [["moovd-loading"]],
    inputs: {
      size: "size"
    },
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    decls: 2,
    vars: 2,
    consts: [[1, "loading"], [1, "loading__icon", 3, "icon", "size"]],
    template: function MoovdLoading_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "moovd-icon", 1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("icon", "phosphorCircleNotch")("size", ctx.size);
      }
    },
    dependencies: [MoovdIcon],
    styles: [".loading[_ngcontent-%COMP%]{display:flex;width:fit-content;height:fit-content}.loading.md[_ngcontent-%COMP%]{font-size:1rem}.loading.lg[_ngcontent-%COMP%]{font-size:1.5rem}.loading__icon[_ngcontent-%COMP%]{animation:_ngcontent-%COMP%_spin .8s linear infinite}@keyframes _ngcontent-%COMP%_spin{to{transform:rotate(360deg)}}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MoovdLoading, [{
    type: Component,
    args: [{
      selector: 'moovd-loading',
      standalone: true,
      imports: [MoovdIcon],
      template: "<div class=\"loading\">\n  <moovd-icon [icon]=\"'phosphorCircleNotch'\" [size]=\"size\" class=\"loading__icon\"></moovd-icon>\n</div>\n",
      styles: [".loading{display:flex;width:fit-content;height:fit-content}.loading.md{font-size:1rem}.loading.lg{font-size:1.5rem}.loading__icon{animation:spin .8s linear infinite}@keyframes spin{to{transform:rotate(360deg)}}\n"]
    }]
  }], null, {
    size: [{
      type: Input
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(MoovdLoading, {
    className: "MoovdLoading"
  });
})();
