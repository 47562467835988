@if (data()) { @for (section of data(); track $index) {
<div class="confirmation-card w-full">
  <div class="confirmation-header w-full">
    <h3 class="text-capitalize">
      {{ section.title | translate }}
    </h3>
    <a [href]="section.editUrl">
      <moovd-icon [icon]="'saxEdit2Outline'" title="edit"></moovd-icon>
    </a>
  </div>
  <div class="confirmation-content">
    @for (data of section.options; track $index) {
    <div class="confirmation-content__card">
      <div class="contirmation-content__title">
        <h5 class="text-capitalize">{{ data.title | translate }}</h5>
      </div>
      @if(data.value){
      <div class="confirmation-content__value">
        @if(data.type === 'image'){
        <div class="logo-wrap">
          <app-image [path]="data.value.url" [title]="data.value.title" />
        </div>
        }@else {
        <p>{{ data.value }}</p>
        }
      </div>
      }
    </div>
    }
  </div>
</div>
} }
