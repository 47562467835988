<div class="chip"
[ngClass]="{
  'primary': color() === 'primary',
  'red': color() === 'red',
  'yellow': color() === 'yellow',
  'green': color() === 'green',
}"
>
  <ng-content></ng-content>
  @if(removeable()){
  <button class="remove-btn" (click)="onDelete()">
    <fa-icon icon="xmark"></fa-icon>
  </button>
  }
</div>
