import { Routes } from "@angular/router";
import { CompanyAdminFormComponent } from "@app/feature/company/components/company-admin-form/company-admin-form.component";
import { CompanyDepartmentFormComponent } from "@app/feature/company/components/company-department-form/company-department-form.component";
import { CompanyInformationFormComponent } from "@app/feature/company/components/company-information-form/company-information-form.component";
import { CompanyProductFormComponent } from "@app/feature/company/components/company-product-form/company-product-form.component";
import { CompanyConfirmationFormComponent } from "@app/feature/company/components/company-confirmation-form/company-confirmation-form.component";

export const COMPANY_FORM_ROUTES: Routes = [
    {
        path: 'information',
        component:  CompanyInformationFormComponent
    },
    {
        path: 'admin',
        component:  CompanyAdminFormComponent
    },
    {
        path: 'department',
        component:  CompanyDepartmentFormComponent
    },
    {
        path: 'product',
        component:  CompanyProductFormComponent
    },
    {
        path: 'confirmation',
        component:  CompanyConfirmationFormComponent
    },
    { path: '', pathMatch: 'full', redirectTo: 'information' },
  { path: '**', redirectTo: 'information' },
]