<app-breadcrumb />
<div class="form-stepper-wrap">
  <div class="form-stepper-content">
    @for (step of companyManagementStep(); track $index) {
    <div class="form-stepper-item" [ngClass]="{'active': step.isActive}">
      <div class="item-step">
        <div class="step-number">
          <span>{{ step.no }}</span>
        </div>
        <div class="step-text">
          <span>{{ step.title | translate}}</span>
        </div>
      </div>
      @if (!step.isLastStep) {
      <div class="item-divider"></div>
      }
    </div>
    }
  </div>
</div>
<div class="form-wrap">
  <div class="form-content">
    <router-outlet></router-outlet>
  </div>
</div>
