import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly localStorage = inject(DOCUMENT)?.defaultView?.localStorage;
  private readonly sessionStorage =
    inject(DOCUMENT)?.defaultView?.sessionStorage;

  constructor() {}

  public localStorageGet<T>(key: string): T | null {
    const item = this.localStorage?.getItem(key);

    if (!item) {
      return null;
    }

    return this.isJSONValid(item) ? (JSON.parse(item) as T) : (item as T);
  }

  public sessionStorageGet<T>(key: string): T | null {
    const item = this.sessionStorage?.getItem(key);

    if (!item) {
      return null;
    }

    return this.isJSONValid(item) ? (JSON.parse(item) as T) : (item as T);
  }

  public localStorageSet(key: string, value: unknown): void {
    this.localStorage?.setItem(key, JSON.stringify(value));
  }

  public sessionStorageSet(key: string, value: unknown): void {
    this.sessionStorage?.setItem(key, JSON.stringify(value));
  }

  public localStorageRemove(key: string): void {
    this.localStorage?.removeItem(key);
  }

  public sessionStorageRemove(key: string): void {
    this.sessionStorage?.removeItem(key);
  }

  public localStorageRemoveKeys(keys: string[]): void {
    keys.forEach((key) => this.localStorage?.removeItem(key));
  }

  public sessionStorageRemoveKeys(keys: string[]): void {
    keys.forEach((key) => this.sessionStorage?.removeItem(key));
  }

  public localStorageClear(): void {
    this.localStorage?.clear();
  }

  public sessionStorageClear(): void {
    this.sessionStorage?.clear();
  }

  private isJSONValid(value: string): boolean {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return false;
    }
  }
}
