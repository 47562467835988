import { OptionInterface } from '@interfaces';
import { CompanyField } from './enums/company.field';
import { TableValueType } from '@types';
import { ListCompaniesOverviewResponse } from './interfaces';

const field = CompanyField;

export const header: OptionInterface<
    keyof ListCompaniesOverviewResponse | 'action' | 'select'
>[] = [
  {
    name: 'Select',
    value: 'select'
  },
  {
    name: 'Company Name',
    value: 'companiesName',
  },
  {
    name: 'Product',
    value: 'productName',
  },
  {
    name: 'Company Admin',
    value: 'companyAdmin',
  },
  {
    name: 'Department / Class',
    value: 'departments',
  },
  {
    name: 'Total Seats',
    value: 'totalSeats',
  },
  {
    name: 'License Type',
    value: 'licenseType',
  },
  {
    name: 'License Status',
    value: 'licenseStatus',
  },
  {
    name: 'Action',
    value: 'action',
  },
];

export const uniqueValue: OptionInterface<TableValueType>[] = [
  {
    name: 'licenseStatus',
    value: 'chip',
  },
];
