import { EMPTY_HASH, UNSIGNED_PAYLOAD } from '../constants.mjs';
import { getHashedDataAsHex } from './dataHashHelpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Returns the hashed payload.
 *
 * @param body `body` (payload) from the request.
 * @returns String created using the payload in the body of the HTTP request as input to a hash function. This string
 * uses lowercase hexadecimal characters. If the payload is empty, return precalculated result of an empty hash.
 *
 * @internal
 */
const getHashedPayload = body => {
  // return precalculated empty hash if body is undefined or null
  if (body == null) {
    return EMPTY_HASH;
  }
  if (isSourceData(body)) {
    const hashedData = getHashedDataAsHex(null, body);
    return hashedData;
  }
  // Defined body is not signable. Return unsigned payload which may or may not be accepted by the service.
  return UNSIGNED_PAYLOAD;
};
const isSourceData = body => typeof body === 'string' || ArrayBuffer.isView(body) || isArrayBuffer(body);
const isArrayBuffer = arg => typeof ArrayBuffer === 'function' && arg instanceof ArrayBuffer || Object.prototype.toString.call(arg) === '[object ArrayBuffer]';
export { getHashedPayload };
