import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import { debounceTime, Observable, of } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { SessionStorageKeyEnum } from '@enums';
import { FormBase } from '@classes';
import { CompanyService, SelectOptionsService, StorageService } from '@services';
import {
  FormComponent,
  FormFieldComponent,
  InputComponent,
  InputInfoMessageComponent,
} from '@components';

import { CompanyField, CompanyProductFormField, FormCompanyProductInterface } from '@app/feature/company/models';
import { SingleSelectInputComponent } from '@app/shared/components/inputs/single-select-input/single-select-input.component';
import { MoovdButton, MoovdLabel } from 'moovd-components';


@Component({
  selector: 'app-company-product-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FormComponent,
    FormFieldComponent,
    SingleSelectInputComponent,
    InputInfoMessageComponent,
    InputComponent,
    MoovdButton,
    MoovdLabel
  ],
  templateUrl: './company-product-form.component.html',
  styleUrl: './company-product-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyProductFormComponent extends FormBase<
FormCompanyProductInterface,
unknown
> {
  private route = inject(Router);
  private destroyRef = inject(DestroyRef);

  public override form = CompanyProductFormField;
  public readonly field = CompanyField;

  public totalAmount = signal<number>(0.5);

  private formStep = this.companyService.formStepEnum;

  constructor(
    public selectOptionsService: SelectOptionsService,
    private storageService: StorageService,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit() {
    this.loadSavedFormData();

    this.form.controls[this.field.PRODUCT_SEATS].valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((seats) => {
        this.totalAmount.set(Number(seats) * 0.5);
      });
  }
  public async back() {
    await this.route.navigate(['superadmin/companies/create/department']);
    this.companyService.formStep.set(this.formStep.COMPANY_DEPARTMENT)
  }

  protected override onSubmit(
    data: FormCompanyProductInterface
  ): Observable<unknown> {
    this.storageService.sessionStorageSet(
      SessionStorageKeyEnum.COMPANY_PRODUCT_FORM,
      data
    );
    this.route.navigate(['superadmin/companies/create/confirmation']);
    this.companyService.formStep.set(this.formStep.COMPANY_CONFIRMATION)
    return of([]);
  }

  private loadSavedFormData() {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyProductInterface>(
        SessionStorageKeyEnum.COMPANY_PRODUCT_FORM
      );
    if (savedData) {
      this.form.setValue(savedData);
    }
  }
}
