<section class="form-wrap w-full h-fit">
  <header class="w-full h-fit mb-16">
    <h2 class="text-capitalize">
      {{ "form.title.company-information" | translate }}
    </h2>
  </header>
  <app-form [form]="form">
    <div class="grid-1">
      <app-form-field
        [label]="'form.label.company-name' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.company-name' | translate"
          [control]="form.controls[field.COMPANY_NAME]"
          [badge]="true"
        />
      </app-form-field>
    </div>

    <div class="grid-2 mt-12">
      <app-form-field
        [label]="'form.label.country' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-single-select-input
          [options]="countriesOption"
          [control]="form.controls[field.COMPANY_COUNTRY]"
          [placeholder]="'form.placeholder.country' | translate"
          [filter]="true"
        />
      </app-form-field>

      <app-form-field
        [label]="'form.label.street' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.street' | translate"
          [control]="form.controls[field.COMPANY_STREET]"
        />
      </app-form-field>
    </div>

    <div class="grid-2 mt-12">
      <app-form-field
        [label]="'form.label.province' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-single-select-input
          [options]="statesOption()"
          [control]="form.controls[field.COMPANY_PROVINCE]"
          [disabled]="statesOption().length == 0 ? true : false"
          [placeholder]="'form.placeholder.province' | translate"
          [filter]="true"
        />
      </app-form-field>

      <app-form-field
        [label]="'form.label.city' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-single-select-input
          [options]="citiesOption()"
          [control]="form.controls[field.COMPANY_CITY]"
          [disabled]="citiesOption().length == 0 ? true : false"
          [placeholder]="'form.placeholder.city' | translate"
          [filter]="true"
        />
      </app-form-field>
    </div>

    <div class="grid-2 mt-12">
      <app-form-field
        [label]="'form.label.postal-code' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.postal-code' | translate"
          [control]="form.controls[field.COMPANY_POSTAL_CODE]"
        />
      </app-form-field>
      <app-form-field
        [label]="'form.label.company-email' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.company-email' | translate"
          [control]="form.controls[field.COMPANY_EMAIL]"
          inputType="email"
        />
      </app-form-field>
    </div>

    <div class="grid-2 mt-12">
      <app-form-field
        [label]="'form.label.phone-number' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-phone-input
          [placeholder]="'form.placeholder.phone-number' | translate"
          [control]="form.controls[field.COMPANY_PHONE_NUMBER]"
        />
      </app-form-field>
      <app-form-field
        [label]="'form.label.billing-email' | translate"
        [required]="true"
        [disableToggle]="true"
        [toggleLabel]="'text.use-company-email' | translate"
        class="text-capitalize"
        [(checkbox)]="useCompanyEmail"
      >
        <app-input
          [placeholder]="'form.placeholder.billing-email' | translate"
          [control]="form.controls[field.COMPANY_BILLING_EMAIL]"
          inputType="email"
          [disabled]="useCompanyEmail()"
        />
      </app-form-field>
    </div>

    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.company-logo' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-file-input
          [control]="form.controls[field.COMPANY_LOGO_TEMP]"
          fileType="image"
        />
      </app-form-field>
    </div>
  </app-form>

  <div class="grid-2 w-full mt-16">
    <div></div>
    <div class="grid-2">
      <moovd-button
        [variant]="'secondary'"
        [stretch]="true"
        [outline]="true"
        (click)="back()"
      >
        <moovd-label>{{ "button.cancel" | translate }}</moovd-label>
      </moovd-button>
      <moovd-button
        [stretch]="true"
        (click)="submit()"
        [isDisabled]="this.checkCompanyLogo() && !form.invalid"
      >
        <moovd-label>{{ "button.next" | translate }}</moovd-label>
      </moovd-button>
    </div>
  </div>
</section>
