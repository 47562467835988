import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonDirective,
  DropdownDirective,
  DropdownComponent,
} from '@app/shared/directives';
import { languageSwitchAnimations } from './app-switch.animation';

import { MobileService, ApplicationService } from 'src/app/core/services';

import { map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarComponent } from '@app/shared/components';
import { ApplicationType } from '@app/core/enums';
import { MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-applicaton-switch',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    DropdownDirective,
    FontAwesomeModule,
    DropdownComponent,
    TranslateModule,
    AvatarComponent,
    MoovdLabel
  ],
  templateUrl: './app-switch.component.html',
  styleUrls: ['./app-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [languageSwitchAnimations.indicatorRotate],
})
export class AppSwitchComponent {
  @ViewChild(DropdownDirective)
  public dropdown?: DropdownDirective;

  @Input()
  public square = false;

  @Input()
  public size: 'lg' | 'md' = 'lg';

  @Input()
  public stretch = false;

  @HostBinding('class')
  public get classes(): Array<string> {
    const array: Array<string> = [this.size];

    this.square && array.push('square');
    return array;
  }

  public currentApplication = this.applicationService.currectApplication$.pipe(
    map((application: string) => {
      console.log('application', application);
      return this.applicationService
        .getApplications()
        .find((app) => app.application === application);
    })
  );

  public applications = this.applicationService.getApplications();

  public isOpen = false;

  constructor(
    public mobileService: MobileService,
    public applicationService: ApplicationService
  ) {}

  public onOpened(): void {
    this.isOpen = true;
  }

  public onClosed(): void {
    this.isOpen = false;
  }

  public changeApp(app: ApplicationType): void {
    if (this.applicationService.getCurrentApplication() !== app) {
    }
    return console.log('change to be', app);
  }
}
