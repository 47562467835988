import { Component, input, model, output} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-field',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
})
export class FormFieldComponent {
  public label = input<string>();
  public required = input<boolean>(false);
  public disableToggle = input<boolean>(false);
  public toggleLabel = input<string>();

  public checkbox = model<boolean>(false);
}
