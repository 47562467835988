import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthAttributes } from './enums/auth-attributes.enum';
import { AuthInterface } from './interfaces/auth.interface';

export type AuthLoginInterface = Pick<
  AuthInterface,
  AuthAttributes.USERNAME | AuthAttributes.PASSWORD
>;

export const AuthLoginFormField = new FormGroup({
  [AuthAttributes.USERNAME]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
  [AuthAttributes.PASSWORD]: new FormControl<string | null>(null, [
    Validators.required,
  ]),
});
