import {
  Component,
  DestroyRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MediaService } from '@services';

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent {
  private destroyRef = inject(DestroyRef);

  public path = input.required<string>();
  public title = input.required<string>();

  public mediaSrc = signal<string | null>(null);

  constructor(private mediaService: MediaService) {}

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.mediaService
      .getMediaUrl(this.path())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.mediaSrc.set(res);
        },
        error: (err) => {
          console.log('err', err);
        },
      });
  }
}
