import { Injectable, signal } from '@angular/core';
import { BaseService } from '../base/base.service';
import { FormControlStatus, QueriesGraphQLEnum } from '@enums';
import { Observable } from 'rxjs';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService extends BaseService<unknown> {
  public loadingCompany = signal(false);

  constructor() {
    super();
  }

  public checkCompanyNameAvailability(
    companyName: string
  ): Observable<boolean | any> {
    return this.queries<{ companyName: string }, unknown>(
      { companyName: companyName },
      QueriesGraphQLEnum.CHECK_COMPANY_EXISTS
    );
  }

  public checkUserEmailAvailability(
    userEmail: string
  ): Observable<boolean | any> {
    return this.queries<{ email: string }, unknown>(
      { email: userEmail },
      QueriesGraphQLEnum.CHECK_EMAIL_EXISTS
    );
  }

  public showErrorMessage(control: AbstractControl) {
    if (!control.touched) {
      return false;
    }
    
    if (control.getError('email')) {
      return true;
    }

    console.log('control.status', control.status);

    return control.status === FormControlStatus.Invalid;
  }
}
