<app-dialog [disableClose]="true">
  <div class="media-view-dialog-wrap">
    <div class="media-view-dialog-content">
      @if (data.mediaType === 'image') {
      <img [src]="data.media" alt="image" />
      }@else {
      <video controls autoplay>
        <source [src]="data.media" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      }
    </div>
  </div>
  <div class="w-full mt-10">
    <moovd-button
    [variant]="'secondary'"
    [outline]="true"
    [stretch]="true"
    (click)="close()"
    >
      <moovd-label>
        {{ "button.close" | translate }}
      </moovd-label>
    </moovd-button>
  </div>
</app-dialog>
