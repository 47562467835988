<div class="searchbar w-full">
  <div class="searchbar__counter">
    <p class="text-capitalize">
      {{ "text.total-data" | translate }} : <span>{{ totalData() }}</span>
    </p>
  </div>

  <div class="searchbar__input">
    <app-form-field>
      <app-input
        #input
        (keyup)="search($event)"
        size="sm"
        [placeholder]="'form.placeholder.search' | translate"
        icon="search"
      >
      </app-input>
    </app-form-field>
  </div>

  <div class="searchbar__button">
    <moovd-button
      [variant]="'secondary'"
      [outline]="true"
      [size]="'sm'"
      class="filter"
      [appDropdown]="dropdown"
    >
      <moovd-label
      [icon]="'saxSetting4Outline'"
      >
        {{"button.filter" | translate}}
      </moovd-label>
    </moovd-button>

    <moovd-button
      [size]="'sm'"
      (click)="buttonActionVoid()"
    >
      <moovd-label
        [icon]="'saxAddOutline'"
      >{{ "button.add-company" | translate }}
      </moovd-label>
    </moovd-button>
  </div>
</div>

@if(selectedData() > 0){
<section class="filter-list w-full">
  <div class="filter-item">
    <div class="filter-item__left">
      <moovd-icon [icon]="'saxMinusSquareOutline'" title="min"></moovd-icon>
    </div>
    <div class="filter-item__center">
      <p>{{ selectedData() }} Company Selected</p>
    </div>
    <div class="filter-item__right">
      <moovd-icon [icon]="'saxTrashOutline'" title="delete"></moovd-icon>
    </div>
  </div>  
</section>
}

<ng-template #dropdown>
  <app-dropdown>
    <header class="dropdown-card">
      <h3 class="text-capitalize">property filter</h3>
      <p (click)="resetVoid()">
        <span class="text-capitalize">reset all</span>
      </p>
    </header>
    <section>
      <ng-content />
    </section>
  </app-dropdown>
</ng-template>
