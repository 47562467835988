import { Component, Input, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import * as IconsaxOutline from '@ng-icons/iconsax/outline';
import * as IconsaxBold from '@ng-icons/iconsax/bold';
import * as PhosphorRegular from '@ng-icons/phosphor-icons/regular';
import * as i0 from "@angular/core";
export class MoovdIcon {
  size;
  icon = input.required();
  static ɵfac = function MoovdIcon_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MoovdIcon)();
  };
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: MoovdIcon,
    selectors: [["moovd-icon"]],
    inputs: {
      size: "size",
      icon: [1, "icon"]
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([], [provideIcons({
      ...IconsaxOutline,
      ...IconsaxBold,
      ...PhosphorRegular
    })]), i0.ɵɵStandaloneFeature],
    decls: 1,
    vars: 3,
    consts: [[3, "name"]],
    template: function MoovdIcon_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "ng-icon", 0);
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.size);
        i0.ɵɵproperty("name", ctx.icon());
      }
    },
    dependencies: [CommonModule, NgIconComponent],
    styles: ["[_nghost-%COMP%]{line-height:0}.md[_ngcontent-%COMP%]{font-size:1rem}.lg[_ngcontent-%COMP%]{font-size:1.5rem}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MoovdIcon, [{
    type: Component,
    args: [{
      selector: 'moovd-icon',
      standalone: true,
      imports: [CommonModule, NgIconComponent],
      viewProviders: [provideIcons({
        ...IconsaxOutline,
        ...IconsaxBold,
        ...PhosphorRegular
      })],
      template: "<ng-icon \n[name]=\"icon()\" \n[class]=\"size\"\n/>",
      styles: [":host{line-height:0}.md{font-size:1rem}.lg{font-size:1.5rem}\n"]
    }]
  }], null, {
    size: [{
      type: Input
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(MoovdIcon, {
    className: "MoovdIcon"
  });
})();
