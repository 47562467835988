import { calculateContentMd5 } from '../../../utils/md5.mjs';
import '../../../../../errors/types/validation.mjs';
import '@aws-amplify/core/internals/utils';
import { logger } from '../../../../../utils/logger.mjs';
import '../../../utils/client/base.mjs';
import '../../../utils/client/getObject.mjs';
import '../../../utils/client/listObjectsV2.mjs';
import '../../../utils/client/putObject.mjs';
import '../../../utils/client/createMultipartUpload.mjs';
import { uploadPart } from '../../../utils/client/uploadPart.mjs';
import '../../../utils/client/completeMultipartUpload.mjs';
import '../../../utils/client/listParts.mjs';
import '../../../utils/client/abortMultipartUpload.mjs';
import '../../../utils/client/copyObject.mjs';
import '../../../utils/client/headObject.mjs';
import '../../../utils/client/deleteObject.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const uploadPartExecutor = async ({
  dataChunkerGenerator,
  completedPartNumberSet,
  s3Config,
  abortSignal,
  bucket,
  finalKey,
  uploadId,
  onPartUploadCompletion,
  onProgress,
  isObjectLockEnabled
}) => {
  let transferredBytes = 0;
  for (const {
    data,
    partNumber,
    size
  } of dataChunkerGenerator) {
    if (abortSignal.aborted) {
      logger.debug('upload executor aborted.');
      break;
    }
    if (completedPartNumberSet.has(partNumber)) {
      logger.debug(`part ${partNumber} already uploaded.`);
      transferredBytes += size;
      onProgress?.({
        transferredBytes
      });
    } else {
      // handle cancel error
      const {
        ETag: eTag
      } = await uploadPart({
        ...s3Config,
        abortSignal,
        onUploadProgress: event => {
          const {
            transferredBytes: currentPartTransferredBytes
          } = event;
          onProgress?.({
            transferredBytes: transferredBytes + currentPartTransferredBytes
          });
        }
      }, {
        Bucket: bucket,
        Key: finalKey,
        UploadId: uploadId,
        Body: data,
        PartNumber: partNumber,
        ContentMD5: isObjectLockEnabled ? await calculateContentMd5(data) : undefined
      });
      transferredBytes += size;
      // eTag will always be set even the S3 model interface marks it as optional.
      onPartUploadCompletion(partNumber, eTag);
    }
  }
};
export { uploadPartExecutor };
