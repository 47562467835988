import { Component, input, signal } from '@angular/core';
import { InputBaseClass } from '@classes';
import { OptionInterface } from '@interfaces';

@Component({
  selector: 'app-radio-input',
  standalone: true,
  imports: [],
  templateUrl: './radio-input.component.html',
  styleUrl: './radio-input.component.scss',
})
export class RadioInputComponent extends InputBaseClass {
  public title = input<string>();
  public options = input.required<OptionInterface[]>();
  public activeRadio = signal<any>(undefined);

  public setRadio(value: any) {
    if (this.activeRadio() !== value) {
      this.activeRadio.set(value);
      this.control.setValue(value);
    } else {
      this.activeRadio.set(null);
      this.control.setValue(null);
    }
  }
}
