import { LicenseStatus, ProductName } from '@types';


export const getCompanyDetailsById = (licenseId: string) => {
  return `
  query MyQuery {
    getCompanyDetailsById(licenseId: "${licenseId}") {
      companyName
      companyEmail
      companyAddress {
        city
        country
        postalCode
        province
        streetAddress
      }
      billingEmail
      companyLogo {
        duration
        size
        title
        url
      }
      companyAdmins {
        firstName
        lastName
        gender
        email
        address
        phoneNumber
      }
      departments {
        name
      }
      phoneNumber
      product {
        billingPeriod
        billingType
        licenseType
        productName
        seats
      }
    }
  }
  `
}



export const listCompanyOverviewQuery = (filter?: {
  licenseStatus?: LicenseStatus;
  productName?: ProductName[];
}) => {
  let productQuery = '';

  filter?.productName?.map((product) => {
    productQuery += `{ productName: { contains: "${product}" } },`;
  });

  const filterQuery = `
  (
  filter: 
  {
    ${
      filter?.productName
        ? `
          or: [
            ${productQuery}
          ],
          `
        : ''
    }
    ${
      filter?.licenseStatus
        ? `
        status: 
            {
                eq:${filter.licenseStatus}
            },
        
        `
        : ''
    }
  }
  )
  `;

  return `
  query MyQuery {
    listCompaniesOverview 
    ${filter ? filterQuery : ''}
    {
        companiesName
        companyAdmin
        companyId
        departments
        licenseId
        licenseStatus
        productName
        totalSeats
    }
}
  `;
};


