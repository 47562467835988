import { Component, input, signal } from '@angular/core';
import { OptionInterface } from '@interfaces';
import { ChipComponent } from '../../chip/chip.component';
import { ChipColor } from '@types';
import { InputBaseClass } from '@classes';

@Component({
  selector: 'app-multiple-chip-input',
  standalone: true,
  imports: [ChipComponent],
  templateUrl: './multiple-chip-input.component.html',
  styleUrl: './multiple-chip-input.component.scss',
})
export class MultipleChipInputComponent extends InputBaseClass{
  public title = input<string>();
  public options = input.required<OptionInterface[]>();
  public choosedOption = signal<OptionInterface[]>([]);


  public isChoosed(option: OptionInterface): ChipColor {
    return this.choosedOption().includes(option) ? 'primary' : 'default';
  }

  public setValue(option: OptionInterface) {
    const available = this.choosedOption().includes(option);
    if (available) {
      this.choosedOption.update((currentData) =>
        currentData.filter((item) => item !== option)
      );
    } else {
      this.choosedOption.update((currentData) => [...currentData, option]);
    }
    this.control.setValue(this.choosedOption().map(option => option.value))
  }
}
