<button
  #button
  appButton
  [appDropdown]="dropdown"
  (opened)="onOpen()"
  (closed)="onClose()"
  [offsetY]="8"
  class="input-wrap"
  [ngClass]="{'disabled': disabled()}"
  type="button"
  [stretch]="stretch()"
> 
  @if (icon) {
    <fa-icon [icon]="icon" class="icon"></fa-icon>
  }
  
  @if (selectedOption) {
    <app-option-label
      class="input-value"
      [option]="selectedOption"
    ></app-option-label>
  }@else {
    <moovd-label>{{ placeholder() }}</moovd-label>
  }

  <fa-icon
      [@chevronRotate]="panelState()"
      class="input-icon"
      icon="chevron-down"
    ></fa-icon>
</button>

<ng-template #dropdown>
  <app-dropdown>
    <app-select-panel
      [filterable]="filter()"
      [options]="options()"
      [control]="control"
      (onSelect)="onSelect()"
    ></app-select-panel>
  </app-dropdown>
</ng-template>
