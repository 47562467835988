import { Injectable } from '@angular/core';
import { ApplicationType } from '@app/core/enums';
import { ApplicationInterface } from '@app/core/interfaces';
import { BehaviorSubject } from 'rxjs';

const applications: Array<ApplicationInterface> = [
  {
    application: ApplicationType.BOOST,
    appLogo: 'assets/images/logos/logo.png',
    appName: "boost"
  }
]

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private readonly _currentApplication = new BehaviorSubject<ApplicationType>(
    ApplicationType.BOOST
  );

  public readonly currectApplication$ =  this._currentApplication.asObservable();


  constructor() { 

  }

  public getCurrentApplication(): ApplicationType{
    return this._currentApplication.getValue();
  }

  public getApplications() {
    return applications;
  }
}
