import {
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss'
})
export class AvatarComponent {
  // TODO: Change the flag icon component to this components

  @Input()
  public isFlag: boolean = false;

  @Input({ required: true })
  public imgSrc!: string;

  @Input()
  @HostBinding('class')
  public size: 'md' | 'lg' = 'md';

  public getImageSource(imageSrc: string): string{
    if(this.isFlag){
      return 'assets/images/flags/' + imageSrc.toLowerCase() + '.svg';
    }
    
    return imageSrc;
  }
}
