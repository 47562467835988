@for (menu of adminMenus; track menu) {
<a [href]="menu.url">
  <button
    class="menu-wrap w-full"
    [ngClass]="{ active: isActive(menu.url) }"
  >
    <div class="menu-icon">
      <moovd-icon [icon]="menu.icon"></moovd-icon>
    </div>

    <div class="menu-title">
      <p class="menu-title-text text-capitalize">
        {{ menu.text | translate }}
      </p>
    </div>
  </button>
</a>
} 
