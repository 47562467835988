import { Directive, HostBinding, Input } from '@angular/core';

export type ButtonVarriant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'red'
  | 'bordered'
  | 'moovd-light-blue-50';
@Directive({
  selector: '[appButton]',
  standalone: true,
})
export class ButtonDirective {
  @Input()
  public appButton?: ButtonVarriant | '';

  @Input()
  public rounded: 'none' | 'md' | 'full' = 'md';

  @Input()
  public outline = false;

  @Input()
  public loading = false;

  @Input()
  public square = false;

  @Input()
  public round = false;

  @Input()
  public shrink = false;

  @Input()
  public stretch = false;

  @Input()
  public shadow = false;

  @Input()
  public size: 'lg' | 'md' | 'sm' = 'md';

  @Input()
  public isDisabled = false;

  @HostBinding('disabled')
  public get disabled(): boolean {
    return this.isDisabled ? this.isDisabled : this.loading;
  }

  @HostBinding('class')
  public get classes(): string[] {
    const classes = ['button', this.size];

    this.appButton ? classes.push(this.appButton) : classes.push('default');
    this.square && classes.push('square');
    this.stretch && classes.push('stretch');
    this.shrink && classes.push('shrink');
    this.isDisabled && classes.push('disabled');
    this.loading && classes.push('loading');
    this.round && classes.push('round');
    this.shadow && classes.push('shadow');
    this.outline && classes.push('outline');

    switch (this.rounded) {
      case 'none':
        classes.push('rounded__none');
        break;
      case 'full':
        classes.push('rounded__full');
        break;
      default:
        classes.push('rounded');
        break;
    }
    return classes;
  }
}
