<div #container>
    <app-input
      #input
      split="right"
      inputType="number"
      [control]="number"
      [placeholder]="placeholder()">
      <button
        prefix
        appButton
        type="button"
        class="input country-code"
        [isDisabled]="isDisabled"
        [appDropdown]="dropdown"
        [offsetY]="8"
        [width]="container.offsetWidth"
        (opened)="onPanelOpened()">
        <ng-container *ngIf="selectedCountry">
          <app-flag-icon class="mr-2" [countryCode]="selectedCountry.code" />
          {{ selectedCountry.dial_code }}
        </ng-container>
      </button>
    </app-input>
  </div>
  <ng-template #dropdown>
    <div class="dropdown-wrapper">
      <app-select-panel
        [filterable]="true"
        [options]="options"
        [control]="country"
        (selected)="onSelect()"></app-select-panel>
    </div>
  </ng-template>
  