import { getRetryDecider, jitteredBackoff, authenticatedHandler, unauthenticatedHandler } from '@aws-amplify/core/internals/aws-client-utils';
import '@aws-amplify/core/internals/utils';
import '../../errors/validation.mjs';
import { parseRestApiServiceError } from '../../utils/serviceError.mjs';
import { logger } from '../../utils/logger.mjs';
import { parseSigningInfo } from '../../utils/parseSigningInfo.mjs';
import { resolveHeaders } from '../../utils/resolveHeaders.mjs';

/**
 * Make REST API call with best-effort IAM auth.
 * @param amplify Amplify instance to to resolve credentials and tokens. Should use different instance in client-side
 *   and SSR
 * @param options Options accepted from public API options when calling the handlers.
 * @param signingServiceInfo Internal-only options enable IAM auth as well as to to overwrite the IAM signing service
 *   and region. If specified, and NONE of API Key header or Auth header is present, IAM auth will be used.
 * @param iamAuthApplicable Callback function that is used to determine if IAM Auth should be used or not.
 *
 * @internal
 */
const transferHandler = async (amplify, options, iamAuthApplicable, signingServiceInfo) => {
  const {
    url,
    method,
    headers,
    body,
    withCredentials,
    abortSignal
  } = options;
  const resolvedBody = body ? body instanceof FormData ? body : JSON.stringify(body ?? '') : undefined;
  const resolvedHeaders = resolveHeaders(headers, body);
  const request = {
    url,
    headers: resolvedHeaders,
    method,
    body: resolvedBody
  };
  const baseOptions = {
    retryDecider: getRetryDecider(parseRestApiServiceError),
    computeDelay: jitteredBackoff,
    withCrossDomainCredentials: withCredentials,
    abortSignal
  };
  const isIamAuthApplicable = iamAuthApplicable(request, signingServiceInfo);
  let response;
  const credentials = await resolveCredentials(amplify);
  if (isIamAuthApplicable && credentials) {
    const signingInfoFromUrl = parseSigningInfo(url);
    const signingService = signingServiceInfo?.service ?? signingInfoFromUrl.service;
    const signingRegion = signingServiceInfo?.region ?? signingInfoFromUrl.region;
    response = await authenticatedHandler(request, {
      ...baseOptions,
      credentials,
      region: signingRegion,
      service: signingService
    });
  } else {
    response = await unauthenticatedHandler(request, {
      ...baseOptions
    });
  }
  // Clean-up un-modeled properties from response.
  return {
    statusCode: response.statusCode,
    headers: response.headers,
    body: response.body
  };
};
const resolveCredentials = async amplify => {
  try {
    const {
      credentials
    } = await amplify.Auth.fetchAuthSession();
    if (credentials) {
      return credentials;
    }
  } catch (e) {
    logger.debug('No credentials available, the request will be unsigned.');
  }
  return null;
};
export { transferHandler };
