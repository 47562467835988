import {
  AbstractControl,
  AsyncValidatorFn,
} from '@angular/forms';
import { ValidatorTypeEnum } from '@enums';
import { ValidatorResponse } from '@interfaces';
import { ValidationService } from '@services';
import { Observable, of, switchMap, timer } from 'rxjs';

export const companyNameAvailability = (): AsyncValidatorFn => {
  const validationService = new ValidationService;
  return (control: AbstractControl): Observable<ValidatorResponse> => {
    return timer(500).pipe(
      switchMap(() =>
        validationService.checkCompanyNameAvailability(control.value as string)
      ),
      switchMap((result) => {
        if (result.data === true || control.pristine) {
          return of({
            type: ValidatorTypeEnum.ERROR,
            message: 'error-message.company-name-availability'
          });
        }
        
        control.markAsTouched();
        return of(null);
      })
    );
  };
};
