import { getCredentialScope } from './getCredentialScope.mjs';
import { getFormattedDates } from './getFormattedDates.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Extracts common values used for signing both requests and urls.
 *
 * @param options `SignRequestOptions` object containing values used to construct the signature.
 * @returns Common `SigningValues` used for signing.
 *
 * @internal
 */
const getSigningValues = ({
  credentials,
  signingDate = new Date(),
  signingRegion,
  signingService,
  uriEscapePath = true
}) => {
  // get properties from credentials
  const {
    accessKeyId,
    secretAccessKey,
    sessionToken
  } = credentials;
  // get formatted dates for signing
  const {
    longDate,
    shortDate
  } = getFormattedDates(signingDate);
  // copy header and set signing properties
  const credentialScope = getCredentialScope(shortDate, signingRegion, signingService);
  return {
    accessKeyId,
    credentialScope,
    longDate,
    secretAccessKey,
    sessionToken,
    shortDate,
    signingRegion,
    signingService,
    uriEscapePath
  };
};
export { getSigningValues };
