<section class="form-wrap w-full h-fit">
  <header class="form-header w-full h-fit">
    <h2 class="text-capitalize">
      {{ "form.title.company-admin" | translate }}
    </h2>
  </header>
  <app-form [form]="form">
    <div class="grid-2">
      <app-form-field
        [label]="'form.label.first-name' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.first-name' | translate"
          [control]="form.controls[field.ADMIN_FIRSTNAME]"
        />
      </app-form-field>
      <app-form-field
        [label]="'form.label.last-name' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.last-name' | translate"
          [control]="form.controls[field.ADMIN_LASTNAME]"
        />
      </app-form-field>
    </div>

    <div class="grid-2 mt-12">
      <app-form-field
        [label]="'form.label.email' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="'form.placeholder.email' | translate"
          [control]="form.controls[field.ADMIN_EMAIL]"
          [badge]="true"
        />
        <app-input-info-message
          [showMessage]="true"
          [message]="'info-message.invitation' | translate"
        />
      </app-form-field>
      <app-form-field
        [label]="'form.label.gender' | translate"
        class="text-capitalize"
        [required]="true"
      >
        <app-single-select-input
          [options]="selectOptionsService.genderOptions"
          [control]="form.controls[field.ADMIN_GENDER]"
          [placeholder]="'form.placeholder.gender' | translate"
        />
      </app-form-field>
    </div>

    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.phone-number' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-phone-input
          [placeholder]="'form.placeholder.phone-number' | translate"
          [control]="form.controls[field.ADMIN_PHONE_NUMBER]"
        />
      </app-form-field>
    </div>

    <div class="grid-1 mt-12">
      <app-form-field
        [label]="'form.label.address' | translate"
        [required]="true"
        class="text-capitalize"
      >
        <app-input
          [placeholder]="companyService.getFilledAddress()"
          [disabled]="true"
        />
        <app-input-info-message
          [showMessage]="true"
          [message]="'info-message.admin-address' | translate"
        />
      </app-form-field>
    </div>
  </app-form>

  <div class="grid-2 w-full mt-16">
    <div></div>
    <div class="grid-2">
      <moovd-button
        [variant]="'secondary'"
        [stretch]="true"
        [outline]="true"
        (click)="back()"
      >
        <moovd-label>{{ "button.back" | translate }}</moovd-label>
      </moovd-button>
      <moovd-button
        [stretch]="true"
        (click)="submit()"
        [isDisabled]="form.invalid"
      >
        <moovd-label>{{ "button.next" | translate }}</moovd-label>
      </moovd-button>
    </div>
  </div>
</section>
