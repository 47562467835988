import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MobileService } from '@app/core/services';
import { UserProfileButtonComponent } from '@app/shared/components/user-profile-button/user-profile-button.component';
import { LanguageSwitchComponent } from '@app/shared/components/language-switch/language-switch.component';
import { SidebarComponent } from './components';
import { RouterModule } from '@angular/router';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SuperAdminMenuComponent } from './components/super-admin-menu/super-admin-menu.component';

@Component({
  selector: 'app-admin-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SidebarComponent,
    NavbarComponent,
    LanguageSwitchComponent,
    UserProfileButtonComponent,
    AdminMenuComponent,
    SuperAdminMenuComponent
  ],
  templateUrl: './admin-layout.component.html',
  styleUrl: './admin-layout.component.scss'
})
export class AdminLayoutComponent {
  constructor(public mobileService: MobileService){}
}
