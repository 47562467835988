<div
  class="upload-area"
  [class.dragging]="dragging()"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave()"
  (drop)="onDrop($event)"
>
  @if (!currentData()) { 
    @if (!mediaService.file() && !loading()) {
      <div class="upload-wrap">
        <div class="upload-icon">
          <fa-icon icon="arrow-up-from-bracket"></fa-icon>
        </div>
        <div class="upload-text">
          <p>{{'text.drag-and-drop' | translate}} <span (click)="onFileSelect()">{{'text.upload-file' | translate}}</span></p>
          <span>{{ ACCESSABLE_TEXT }}</span>
        </div>
      </div>
    }@else if (mediaService.file() && loading()){
      <div class="uploaded-file-wrap">
        <div class="uploaded-file-info">
          <div class="info-image-wrap">
            @if( mediaService.mediaSrc() ){
              <img [src]="mediaService.mediaSrc()" alt="logo" />
            }
            <moovd-icon [icon]="'saxGalleryOutline'"/>
          </div>
          <p class="info-file-title">{{ mediaService.file()?.name }}</p>
        </div>
        <div class="progress-wrap">
          <div class="uploaded-file-progress">
            <div class="progress-bar" [style.width.%]="progress()"></div>
          </div>
          <div>
            <fa-icon icon="circle-xmark"></fa-icon>
          </div>
        </div>
        <div class="file-info-wrap">
          <span>{{ getFileSize(mediaService.file()?.size) }}</span>
          <span>{{ progress() }}%</span>
        </div>
      </div>
    }@else { 
      <div class="preview-file-wrap">
        <div class="uploaded-file-info">
          <div class="info-image-wrap">
            @if( this.mediaService.mediaSrc() ){
              <img [src]="this.mediaService.mediaSrc()" alt="logo" />
            }
            <moovd-icon [icon]="'saxGalleryOutline'"/>
          </div>
          <p class="info-file-title">{{ mediaService.file()?.name }}</p>
        </div>
        <div class="preview-action-wrap">
          <div
            class="action-view"
            (click)="
              openMediaViewDetail({
                media: this.mediaService.mediaSrc(),
                mediaType: 'image'
              })
            "
          >
            <span>{{'text.view' | translate}}</span>
          </div>
          <div (click)="removeFile()" class="action-delete">
            <fa-icon icon="trash"></fa-icon>
          </div>
        </div>
      </div>
    } 
  }@else{ 
    <div class="preview-file-wrap">
      <div class="uploaded-file-info">
        <div class="info-image-wrap">
          <app-image [path]="this.currentData()?.url || ''" [title]="this.currentData()?.title || ''"></app-image>      
          <moovd-icon [icon]="'saxGalleryOutline'"/>
        </div>
        <p class="info-file-title">{{ currentData()?.title }}</p>
      </div>
      <div class="preview-action-wrap">
        @if(!mediaPerform.isLoading && mediaPerform.data){
        <div
          class="action-view"
          (click)="
            openMediaViewDetail({
              media: mediaPerform.data,
              mediaType: 'image'
            })
          "
        >
          <span>{{'text.view' | translate}}</span>
        </div>
        }
        <div (click)="removeFile()" class="action-delete">
          <fa-icon icon="trash"></fa-icon>
        </div>
      </div>
    </div>
  } 


  <input
    type="file"
    #fileInput
    [accept]="ACCESSABLE_FILE_TYPE"
    (change)="onFileChange($event)"
    hidden
    [formControl]="control"
  />

  @if (error()) {
    <span class="error-message">{{ error() }}</span>
  }
</div>

<app-input-error-message
  [showMessage]="control.getError('required') && control.touched"
  [message]="'error-message.required' | translate"
/>