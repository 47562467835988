import {
  FormCompanyInformationInterface,
  FormCompanyAdminInterface,
  FormCompanyProductInterface,
  CompanyField,
} from '../models';

const field = CompanyField;

export interface DetailOptionInterface {
  title: string;
  value: any;
}

export function getInformationConfirmation(
  data: FormCompanyInformationInterface
): DetailOptionInterface[] {
  return [
    {
      title: 'form.label.company-name',
      value: data[field.COMPANY_NAME],
    },
    {
      title: 'form.label.country',
      value: data[field.COMPANY_COUNTRY],
    },
    {
      title: 'form.label.street',
      value: data[field.COMPANY_STREET],
    },
    {
      title: 'form.label.province',
      value: data[field.COMPANY_PROVINCE],
    },
    {
      title: 'form.label.city',
      value: data[field.COMPANY_CITY],
    },
    {
      title: 'form.label.postal-code',
      value: data[field.COMPANY_POSTAL_CODE],
    },
    {
      title: 'form.label.company-email',
      value: data[field.COMPANY_EMAIL],
    },
    {
      title: 'text.phone-number',
      value: data[field.COMPANY_PHONE_NUMBER],
    },
    {
      title: 'form.label.billing-email',
      value: data[field.COMPANY_BILLING_EMAIL],
    },
    {
      title: 'form.label.company-logo',
      value: '',
    },
  ];
}

export function getAdminConfirmation(
  data: FormCompanyAdminInterface
): DetailOptionInterface[] {
  return [
    {
      title: 'form.label.first-name',
      value: data[field.ADMIN_FIRSTNAME],
    },
    {
      title: 'form.label.last-name',
      value: data[field.ADMIN_LASTNAME],
    },
    {
      title: 'form.label.email',
      value: data[field.ADMIN_EMAIL],
    },
    {
      title: 'text.phone-number',
      value: data[field.ADMIN_PHONE_NUMBER],
    },
    {
      title: 'form.label.gender',
      value: data[field.ADMIN_GENDER],
    },
    {
      title: 'form.label.address',
      value: null,
    },
  ];
}

export function getProductConfirmation(
  data: FormCompanyProductInterface
): DetailOptionInterface[] {
  return [
    {
      title: 'form.label.company-product',
      value: 'Boost',
    },
    {
      title: 'form.label.license-type',
      value: 'WeMind Boost',
    },
    {
      title: 'form.label.billing-period',
      value: 'monthly',
    },
    {
      title: 'form.label.billing-type',
      value: 'Billing By Moovd',
    },
    {
      title: 'text.seats',
      value: Number(data[field.PRODUCT_SEATS]),
    },
    {
      title: 'text.amount-and-total',
      value: `USD 0.5 - (USD ${Number(data[field.PRODUCT_SEATS]) * 0.5})`,
    },
  ];
}
