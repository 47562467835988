@switch (type()) { 
  @case ('chip') {
    <app-chip class="text-capitalize"
    [color]="getChipColor(value())"
    >{{ value() }}</app-chip>
  } 
  @case ('image') {
    <div class="image-wrap">
      <app-image [path]="value().url" [title]="value()"></app-image>
    </div>
  } 
  @default{
    <p>{{ value() }}</p>
  } 
}
