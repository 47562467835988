<button
  #button
  appButton
  [appDropdown]="dropdown"
  [offsetY]="8"
  class="flag-button"
  [size]="this.size"
  [stretch]="stretch"
  [class.square]="square"
  (opened)="onOpened()"
  (closed)="onClosed()"
>
  <app-avatar class="flag" imgSrc="assets/images/logos/moovd-logo.png" />

  @if (!square) {
  <div>
    <moovd-label class="label-name">{{ authService.userId() }}</moovd-label>
    <moovd-label class="label-company">Moovd B.V</moovd-label>
  </div>
  <fa-icon icon="chevron-down" class="chevron" [@indicatorRotate]="isOpen" />
  }
</button>

<ng-template #dropdown>
  <app-dropdown>
    <moovd-button [variant]="'default'" [shadow]="true" [stretch]="true" (click)="signOut()">
      <moovd-label 
      [icon]="'saxArrowLeftOutline'"
      class="label-setting">{{
        "user.profile.sign.out" | translate
      }}</moovd-label>
    </moovd-button>
  </app-dropdown>
</ng-template>
