import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-badge',
  standalone: true,
  imports: [],
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {
  @Input()
  @HostBinding('class')
  public color: 'primary' | 'secondary' | 'light' = 'primary';
}
