// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Symbol used for internal user agent overrides.
 *
 * @internal
 * This symbol is intended for internal use within the Amplify library.
 * It may change or be removed in future versions without notice.
 * External usage of this symbol is discouraged and may lead to unexpected behavior.
 */
const INTERNAL_USER_AGENT_OVERRIDE = Symbol('INTERNAL_USER_AGENT_OVERRIDE');
var AiAction;
(function (AiAction) {
  AiAction["CreateConversation"] = "1";
  AiAction["GetConversation"] = "2";
  AiAction["ListConversations"] = "3";
  AiAction["DeleteConversation"] = "4";
  AiAction["SendMessage"] = "5";
  AiAction["ListMessages"] = "6";
  AiAction["OnMessage"] = "7";
  AiAction["Generation"] = "8";
})(AiAction || (AiAction = {}));
const getCustomUserAgentDetails = action => ({
  category: 'ai',
  action
});
/**
 * Creates a user agent override object based on custom details.
 *
 * @internal
 * This function is intended for internal use within the Amplify library.
 * It may change or be removed in future versions without notice.
 *
 * @param customUserAgentDetails - Optional custom user agent details
 * @returns An object with INTERNAL_USER_AGENT_OVERRIDE symbol as key and customUserAgentDetails as value, or undefined if no details provided
 */
function createUserAgentOverride(customUserAgentDetails) {
  return customUserAgentDetails ? {
    [INTERNAL_USER_AGENT_OVERRIDE]: customUserAgentDetails
  } : undefined;
}
export { AiAction, INTERNAL_USER_AGENT_OVERRIDE, createUserAgentOverride, getCustomUserAgentDetails };
