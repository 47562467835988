import { Injectable } from '@angular/core';
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from 'country-state-city';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor() {}

  public getListOfCountries(): ICountry[] {
    return Country.getAllCountries();
  }

  public getCountryByIsoCode(countryIsoCode: string): ICountry | undefined{
    return Country.getCountryByCode(countryIsoCode);
  }

  public getListOfStates(countryIsoCode: string): IState[] {
    return State.getStatesOfCountry(countryIsoCode);
  }

  public getStateByIsoCode(stateIsoCode: string): IState | undefined{
    return State.getStateByCode(stateIsoCode);
  }

  public getListOfCities(countryIsoCode: string, stateIsoCode: string): ICity[] {
    return City.getCitiesOfState(countryIsoCode, stateIsoCode);
  }
}
