import { StorageAction } from '@aws-amplify/core/internals/utils';
import '@smithy/md5-js';
import '@aws-amplify/core/internals/aws-client-utils';
import '../../utils/client/runtime/s3TransferHandler/fetch.mjs';
import 'fast-xml-parser';
import '../../utils/client/runtime/s3TransferHandler/xhr.mjs';
import 'buffer';
import { resolveS3ConfigAndInput } from '../../utils/resolveS3ConfigAndInput.mjs';
import '../../../../errors/types/validation.mjs';
import { logger } from '../../../../utils/logger.mjs';
import { validateStorageOperationInput } from '../../utils/validateStorageOperationInput.mjs';
import { STORAGE_INPUT_KEY } from '../../utils/constants.mjs';
import '../../utils/client/base.mjs';
import '../../utils/client/getObject.mjs';
import '../../utils/client/listObjectsV2.mjs';
import '../../utils/client/putObject.mjs';
import '../../utils/client/createMultipartUpload.mjs';
import '../../utils/client/uploadPart.mjs';
import '../../utils/client/completeMultipartUpload.mjs';
import '../../utils/client/listParts.mjs';
import '../../utils/client/abortMultipartUpload.mjs';
import '../../utils/client/copyObject.mjs';
import { headObject } from '../../utils/client/headObject.mjs';
import '../../utils/client/deleteObject.mjs';
import { getStorageUserAgentValue } from '../../utils/userAgent.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const getProperties = async (amplify, input, action) => {
  const {
    options: getPropertiesOptions
  } = input;
  const {
    s3Config,
    bucket,
    keyPrefix,
    identityId
  } = await resolveS3ConfigAndInput(amplify, getPropertiesOptions);
  const {
    inputType,
    objectKey
  } = validateStorageOperationInput(input, identityId);
  const finalKey = inputType === STORAGE_INPUT_KEY ? keyPrefix + objectKey : objectKey;
  logger.debug(`get properties of ${objectKey} from ${finalKey}`);
  const response = await headObject({
    ...s3Config,
    userAgentValue: getStorageUserAgentValue(action ?? StorageAction.GetProperties)
  }, {
    Bucket: bucket,
    Key: finalKey
  });
  const result = {
    contentType: response.ContentType,
    size: response.ContentLength,
    eTag: response.ETag,
    lastModified: response.LastModified,
    metadata: response.Metadata,
    versionId: response.VersionId
  };
  return inputType === STORAGE_INPUT_KEY ? {
    key: objectKey,
    ...result
  } : {
    path: objectKey,
    ...result
  };
};
export { getProperties };
