<table mat-table [dataSource]="dataSource()" matSort>
  <!-- checkbox -->

  @for (column of getColumns(headers()); track $index) { @if (column ===
  'select') {
  <ng-container matColumnDef="select" class="header-left" [sticky]="true">
    <th mat-header-cell *matHeaderCellDef>
      <div class="header-title-wrap"></div>
    </th>
    <td mat-cell *matCellDef="let row">
      <div class="checkbox">
        <input type="checkbox" (click)="setSelectedData(row, $event)" />
      </div>
    </td>
  </ng-container>
  }@else {
  <ng-container [matColumnDef]="column">
    <th *matHeaderCellDef mat-sort-header>
      <div class="header-title-wrap">
        <span class="header-title-text">{{ getHeader(column) }}</span>
      </div>
    </th>
    @if(column === 'action'){
    <td *matCellDef="let row">
      <app-table-action-data-value
        (viewDetail)="viewContentDetail(row)"
        (update)="updateContent(row)"
        (delete)="deleteContent(row)"
      />
    </td>
    }@else {
    <td *matCellDef="let row" class="truncate">
      <app-table-data-value
        [type]="getValueType(column)"
        [value]="getTableValue(column, row[column])"
      ></app-table-data-value>
    </td>
    }
  </ng-container>
  } }
  <tr
    mat-header-row
    *matHeaderRowDef="getColumns(headers()); sticky: true"
  ></tr>
  <tr mat-row *matRowDef="let row; columns: getColumns(headers())"></tr>
  @if (!loading()) {
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [colSpan]="this.getColumns(headers()).length">
      <app-table-no-data-value
        [dataCount]="dataSource().data.length"
        [contentName]="contentName()"
        (buttonAction)="noDataButtonActionVoid()"
        [showAddButton]="showAddButton()"
      />
    </td>
  </tr>
  }
</table>

@if(loading()){
<div class="loading-wrap">
  <moovd-loading [size]="'lg'" />
</div>
}
