/* eslint-disable @angular-eslint/directive-selector */
import { Directive, HostBinding, Input } from '@angular/core';
import * as i0 from "@angular/core";
export class ButtonDirective {
  appButton;
  rounded = 'md';
  outline = false;
  loading = false;
  square = false;
  round = false;
  shrink = false;
  stretch = false;
  shadow = false;
  size = 'md';
  isDisabled = false;
  get disabled() {
    return this.isDisabled ? this.isDisabled : this.loading;
  }
  get classes() {
    const classes = ['button', this.size];
    this.appButton ? classes.push(this.appButton) : classes.push('default');
    this.square && classes.push('square');
    this.stretch && classes.push('stretch');
    this.shrink && classes.push('shrink');
    this.isDisabled && classes.push('disabled');
    this.loading && classes.push('loading');
    this.round && classes.push('round');
    this.shadow && classes.push('shadow');
    this.outline && classes.push('outline');
    switch (this.rounded) {
      case 'none':
        classes.push('rounded__none');
        break;
      case 'full':
        classes.push('rounded__full');
        break;
      default:
        classes.push('rounded');
        break;
    }
    return classes;
  }
  static ɵfac = function ButtonDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ButtonDirective)();
  };
  static ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
    type: ButtonDirective,
    selectors: [["", "appButton", ""]],
    hostVars: 3,
    hostBindings: function ButtonDirective_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵhostProperty("disabled", ctx.disabled);
        i0.ɵɵclassMap(ctx.classes);
      }
    },
    inputs: {
      appButton: "appButton",
      rounded: "rounded",
      outline: "outline",
      loading: "loading",
      square: "square",
      round: "round",
      shrink: "shrink",
      stretch: "stretch",
      shadow: "shadow",
      size: "size",
      isDisabled: "isDisabled"
    },
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonDirective, [{
    type: Directive,
    args: [{
      selector: '[appButton]',
      standalone: true
    }]
  }], null, {
    appButton: [{
      type: Input
    }],
    rounded: [{
      type: Input
    }],
    outline: [{
      type: Input
    }],
    loading: [{
      type: Input
    }],
    square: [{
      type: Input
    }],
    round: [{
      type: Input
    }],
    shrink: [{
      type: Input
    }],
    stretch: [{
      type: Input
    }],
    shadow: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    disabled: [{
      type: HostBinding,
      args: ['disabled']
    }],
    classes: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
