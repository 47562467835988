import { inject, Injectable } from '@angular/core';
import { LocationService } from '../location/location.service';
import { OptionInterface } from '@interfaces';
import { genderOptions, licenseOptions, productOptions } from './data';
@Injectable({
  providedIn: 'root'
})
export class SelectOptionsService {

  private locationService = inject(LocationService);

  public readonly genderOptions = genderOptions;
  public readonly productOptions = productOptions;
  public readonly licenseOptions = licenseOptions;

  constructor() { }

  public getCountries(): OptionInterface[]{
    return this.locationService.getListOfCountries()
    .filter((country) => {
      if(
        this.getStates(country.isoCode).length == 0
      ){
        return false
      }else{
        return true
      }
    })
    .map((country) => (
      {
        name: country.name,
        value: country.isoCode
      }
    ))
  }

  public getStates(countryIsoCode: string): OptionInterface[]{
    return this.locationService.getListOfStates(countryIsoCode)

    .map((state) => (
      {
        name: state.name,
        value: state.isoCode
      }
    ))
  }

  public getCities(countryIsoCode: string, cityIsoCode: string): OptionInterface[]{
    return this.locationService.getListOfCities(countryIsoCode, cityIsoCode)
    .map((city) => (
      {
        name: city.name, 
        value: city.name.toLowerCase()
      }
    ))
  }
}
