import { parseMetadata } from '@aws-amplify/core/internals/aws-client-utils';
import { AmplifyUrl } from '@aws-amplify/core/internals/utils';
import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { defaultConfig } from './base.mjs';
import { parseXmlError, parseXmlBody } from './utils/parsePayload.mjs';
import { s3TransferHandler } from './runtime/s3TransferHandler/fetch.mjs';
import 'fast-xml-parser';
import './runtime/s3TransferHandler/xhr.mjs';
import 'buffer';
import { buildStorageServiceError, map } from './utils/deserializeHelpers.mjs';
import { serializeObjectConfigsToHeaders, validateS3RequiredParameter, serializePathnameObjectKey } from './utils/serializeHelpers.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const createMultipartUploadSerializer = async (input, endpoint) => {
  const headers = await serializeObjectConfigsToHeaders(input);
  const url = new AmplifyUrl(endpoint.url.toString());
  validateS3RequiredParameter(!!input.Key, 'Key');
  url.pathname = serializePathnameObjectKey(url, input.Key);
  url.search = 'uploads';
  return {
    method: 'POST',
    headers,
    url
  };
};
const createMultipartUploadDeserializer = async response => {
  if (response.statusCode >= 300) {
    const error = await parseXmlError(response);
    throw buildStorageServiceError(error, response.statusCode);
  } else {
    const parsed = await parseXmlBody(response);
    const contents = map(parsed, {
      UploadId: 'UploadId'
    });
    return {
      $metadata: parseMetadata(response),
      ...contents
    };
  }
};
const createMultipartUpload = composeServiceApi(s3TransferHandler, createMultipartUploadSerializer, createMultipartUploadDeserializer, {
  ...defaultConfig,
  responseType: 'text'
});
export { createMultipartUpload };
