import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ValidatorTypeEnum } from '@enums';
import { ValidatorResponse } from '@interfaces';

export function seatsMinimum(): ValidatorFn {
  return (control: AbstractControl): ValidatorResponse => {
    const value = control.value;
    return Number(value) < 1
      ? {
          type: ValidatorTypeEnum.ERROR,
          message: 'error-message.seats-minimum',
        }
      : null;
  };
}
