import { Routes } from '@angular/router';
import { AuthLoginComponent } from '@app/feature/auth/components/auth-login/auth-login.component';
import { AuthVerifyComponent } from '@app/feature/auth/components/auth-verify/auth-verify.component';

export const AUTH_ROUTES: Routes = [
  {
    path: 'login',
    component: AuthLoginComponent
  },
  {
    path: 'verify',
    component: AuthVerifyComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', redirectTo: '' },
];