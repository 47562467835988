import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MoovdIcon } from 'moovd-components';
@Component({
  selector: 'app-input-info-message',
  standalone: true,
  imports: [CommonModule, MoovdIcon],
  templateUrl: './input-info-message.component.html',
  styleUrl: './input-info-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputInfoMessageComponent {
  public readonly message = input.required<string>();
  public readonly showMessage = input.required<boolean>();
}
