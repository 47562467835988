import { Injectable } from '@angular/core';
import { generateClient, GraphQLResult } from 'aws-amplify/api';
import { from, Observable } from 'rxjs';
import {
  ModelsGraphQLEnum,
  MutationsGraphQLEnum,
  QueriesGraphQLEnum,
} from '@enums';
import { IResponse } from '@app/core/interfaces';
import { uploadData } from '@aws-amplify/storage';

@Injectable({
  providedIn: 'root',
})
export class BaseService<T> {
  private amplifyClient = generateClient<any>({ authMode: 'apiKey' });

  constructor() {}

  protected getAllData(
    modelName: ModelsGraphQLEnum
  ): Observable<IResponse<T[]>> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.models[modelName]['list'] as unknown as Observable<
          IResponse<T[]>
        >
      );
    }

    return from(Promise.reject());
  }

  protected getDataById(
    id: string,
    modelName: ModelsGraphQLEnum
  ): Observable<IResponse<T>> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.models[modelName]['get']({
          id: id,
        }) as unknown as Observable<IResponse<T>>
      );
    }

    return from(Promise.reject());
  }

  protected createData<T>(
    data: T,
    mutation: MutationsGraphQLEnum
  ): Observable<any> {
    return from(this.amplifyClient.mutations[mutation](data));
  }

  protected postMutation<T, R>(
    data: T,
    mutation: MutationsGraphQLEnum
  ): Observable<IResponse<R>> {
    return from(this.amplifyClient.mutations[mutation](data));
  }

  protected addData(data: any, modelName: ModelsGraphQLEnum): Observable<any> {
    if (this.amplifyClient.models[modelName]) {
      return from(this.amplifyClient.models[modelName]['create'](data));
    }
    return from(Promise.reject());
  }

  protected deleteData(
    id: string,
    modelName: ModelsGraphQLEnum
  ): Observable<any> {
    if (this.amplifyClient.models[modelName]) {
      return from(
        this.amplifyClient.models[modelName]['delete']({
          id: id,
        })
      );
    }

    return from(Promise.reject());
  }

  protected uploadFile(
    basePath: string,
    file: { title: string; value: ArrayBuffer }
  ): Observable<any> {
    return from(
      uploadData({
        path: basePath + file.title,
        data: file.value,
      }).result
    );
  }

  protected queries<T, R>(data: T, query: QueriesGraphQLEnum): Observable<R> {
    return from(this.amplifyClient.queries[query](data));
  }

  protected graphql<R>(query: string): Observable<GraphQLResult<R>> {
    return from(
      this.amplifyClient.graphql({
        query: query,
      })
    );
  }
}
