import { Injectable, signal } from '@angular/core';
import { map, Observable } from 'rxjs';

import { MutationsGraphQLEnum, SessionStorageKeyEnum } from '@enums';
import { IResponse, MediaInterface } from '@interfaces';
import {
  StorageService,
  LocationService,
  BaseService,
  ProgressService,
} from '@services';

import {
  CreateCompanyAdminInterface,
  CreateCompanyDepartmentInterface,
  CreateCompanyProductInterface,
  CreateCompanyInformationInterface,
  FormCompanyInformationInterface,
  CreateCompanyInterface,
  CompanyField,
  ListCompaniesOverviewResponse,
  getCompanyDetailsByIdResponse,
} from '@app/feature/company/models';
import { LicenseStatus, ProductName } from '@types';
import {
  listCompanyOverviewQuery,
  getCompanyDetailsById,
} from './company.graphql.query';

enum CompanyFormStepEnum {
  COMPANY_INFORMATION,
  COMPANY_ADMIN,
  COMPANY_DEPARTMENT,
  COMPANY_PRODUCT,
  COMPANY_CONFIRMATION,
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseService<unknown> {
  public formStepEnum = CompanyFormStepEnum;
  public formStep = signal<CompanyFormStepEnum>(
    this.formStepEnum.COMPANY_INFORMATION
  );
  public readonly field = CompanyField;
  public companyLogoTemp = signal<MediaInterface | null>(null);

  constructor(
    private locationService: LocationService,
    private storageService: StorageService,
    private progressService: ProgressService
  ) {
    super();
  }

  public listCompaniesOverview(filter?: {
    licenseStatus?: LicenseStatus;
    productName?: ProductName[];
  }): Observable<ListCompaniesOverviewResponse[]> {
    return this.graphql<{
      listCompaniesOverview: ListCompaniesOverviewResponse[];
    }>(listCompanyOverviewQuery(filter)).pipe(
      map((res) => {
        const transformedResponse = res.data.listCompaniesOverview.map(field => (
          {
            ...field,
            licenseType: 'Boost License'
          }
        ))
        return transformedResponse;
      })
    );
  }

  public getCompanyDetail(
    licenseId: string
  ): Observable<getCompanyDetailsByIdResponse> {
    return this.graphql<{
      getCompanyDetailsById: getCompanyDetailsByIdResponse;
    }>(getCompanyDetailsById(licenseId)).pipe(
      map((res) => {
        return res.data.getCompanyDetailsById;
      })
    );
  }

  public createCompany(
    informationData: CreateCompanyInformationInterface,
    adminData: CreateCompanyAdminInterface,
    departmentData: CreateCompanyDepartmentInterface,
    productData: CreateCompanyProductInterface
  ): Observable<IResponse<unknown>> {
    const data: CreateCompanyInterface = {
      ...informationData,
      ...adminData,
      ...departmentData,
      ...productData,
      [this.field.LOCALE]: 'nl_NL',
    };
    return this.postMutation<CreateCompanyInterface, unknown>(
      data,
      MutationsGraphQLEnum.CREATE_COMPANY_BY_ADMIN
    );
  }

  public getFilledAddress(): string {
    const savedData =
      this.storageService.sessionStorageGet<FormCompanyInformationInterface>(
        SessionStorageKeyEnum.COMPANY_INFORMATION_FORM
      );

    if (savedData) {
      const countryIsoCode = savedData[this.field.COMPANY_COUNTRY];
      const stateIsoCode = savedData[this.field.COMPANY_PROVINCE];

      const country =
        this.locationService.getCountryByIsoCode(countryIsoCode)?.name;
      const state = this.locationService.getStateByIsoCode(stateIsoCode)?.name;
      const city = savedData[this.field.COMPANY_CITY];
      const street = savedData[this.field.COMPANY_STREET];
      const postalCode = savedData[this.field.COMPANY_POSTAL_CODE];

      return (
        street.toUpperCase() +
        ', ' +
        city.toUpperCase() +
        ', ' +
        state?.toUpperCase() +
        ', ' +
        country?.toUpperCase() +
        ', ' +
        postalCode
      );
    }

    return '';
  }

  public uploadCompanyLogo(data: { title: string; value: ArrayBuffer }) {
    const basePath = 'company/logo/';
    return this.uploadFile(basePath, data);
  }
}
