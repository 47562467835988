export enum CompanyField {
  COMPANY_ID = 'id',

  COMPANY_NAME = 'companyName',
  COMPANY_COUNTRY = 'country',
  COMPANY_STREET = 'streetAddress',
  COMPANY_PROVINCE = 'region',
  COMPANY_CITY = 'locality',
  COMPANY_POSTAL_CODE = 'postalCode',
  COMPANY_EMAIL = 'companyEmail',
  COMPANY_PHONE_NUMBER = 'companyPhoneNumber',
  COMPANY_BILLING_EMAIL = 'billingEmail',
  COMPANY_LOGO_TEMP = 'companyLogo',
  COMPANY_LOGO_TITLE = 'logoTitle',
  COMPANY_LOGO_URL = 'logoUrl',
  COMPANY_LOGO_SIZE = 'logoSize',
  COMPANY_LOGO_VALUE = 'value',

  ADMIN_FIRSTNAME = 'firstName',
  ADMIN_LASTNAME = 'lastName',
  ADMIN_ADDRESS = 'userAddress',
  ADMIN_EMAIL = 'email',
  ADMIN_GENDER = 'gender',
  ADMIN_PHONE_NUMBER = 'phoneNumber',
  ADMIN_ROLE = 'roles',

  DEPARTMENT_LIST = 'departments',

  PRODUCT_NAME = 'productName',
  PRODUCT_ID = 'productId',
  PRODUCT_BILLING_PERIOD = 'paymentTerm',
  PRODUCT_SEATS = 'seats',

  LICENSE_TYPE = 'licenseType',
  LICENSE_STATUS = 'status',

  LOCALE = 'locale',

  OWNER_IDS = 'ownerIds',
  USERS = 'users',
  LICENSE_ID = 'licenseId',
  CREATED_AT = 'createdAt',
  PRODUCT_DESC = 'productDesc',
  FREE_LIMIT_INTERVAL = 'freeLimitInterval',
  FREE_LIMIT_QUOTUM = 'freeLimitQuotum',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  FREE_LIMIT_USED = 'freeLimitUsed',

  ADMIN_FULL_NAME = 'adminFullName',
  DEPARTMENT_TOTAL = 'totalDepartment'
}
