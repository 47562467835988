import {
  DetailOptionInterface,
  DetailSectionInterface,
  MediaInterface,
  OptionInterface,
} from '@interfaces';
import { CompanyField, ListCompanySuperAdminResponse } from '../models';

const field = CompanyField;

export const data: DetailSectionInterface[] = [
  {
    title: 'form.title.company-information',
    editUrl: '/admin/companies/create/company-information',
    options: [
      {
        title: 'form.label.company-name',
        value: 'Mayapada',
      },
      {
        title: 'form.label.country',
        value: 'UNITED STATES OF AMERIKA',
      },
      {
        title: 'form.label.street',
        value: '742 LYME STREET',
      },
      {
        title: 'form.label.province',
        value: 'Florida',
      },
      {
        title: 'form.label.city',
        value: 'Tallahassee',
      },
      {
        title: 'form.label.postal-code',
        value: 'NY 11211',
      },
      {
        title: 'form.label.company-email',
        value: 'my.company@company.com',
      },
      {
        title: 'text.phone-number',
        value: '+91 12345 6578',
      },
      {
        title: 'form.label.billing-email',
        value: 'my.company@gmail.com',
      },
      {
        title: 'form.label.company-logo',
        value: {
          title: 'logo',
          url: 'company/logo/we_mind_cms_logo_28102024145824.png',
        } as MediaInterface,
        type: 'image',
      },
    ],
  },
  {
    title: 'form.title.company-department',
    editUrl: '/admin/companies/create/company-department',
    options: [
      {
        title: '1. Class 1-A',
      },
      {
        title: '2. Class 1-B',
      },
      {
        title: '3. Class 1-C',
      },
      {
        title: '4. Class 1-D',
      },
      {
        title: '5. Class 1-E',
      },
      {
        title: '6. Class 1-F',
      },
      {
        title: '7. Class 2-A',
      },
      {
        title: '8. Class 2-B',
      },
    ],
  },
  {
    title: 'title.your-information',
    editUrl: '',
    options: [
      {
        title: 'form.label.first-name',
        value: 'Stefen',
      },
      {
        title: 'form.label.last-name',
        value: 'Wongonius',
      },
      {
        title: 'form.label.email',
        value: 'stefen@mycompany.com',
      },
      {
        title: 'form.label.phone-number-2',
        value: '+91 24927972 5835',
      },
      {
        title: 'form.label.country',
        value: 'UNITED STATES OF AMERIKA',
      },
      {
        title: 'form.label.street',
        value: '742 LYME STREET',
      },
      {
        title: 'form.label.province',
        value: 'Florida',
      },
      {
        title: 'form.label.city',
        value: 'Tallahassee',
      },
      {
        title: 'form.label.postal-code',
        value: 'NY 11211',
      },
    ],
  },
];

export const statusOptions: OptionInterface[] = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
  { name: 'Waiting', value: 'waiting' },
];

export const activeDateOptions: OptionInterface[] = [
  { name: 'This Week', value: 'this_week' },
  { name: 'This Month', value: 'this_month' },
  { name: 'Last 6 Month', value: 'last_6_month' },
  { name: 'This Year', value: 'this_year' },
];

export const licenseOptions: OptionInterface[] = [
  {
    name: 'All License',
    value: 'all_license',
  },
  {
    name: 'Boost',
    value: 'boost',
  },
  {
    name: 'WeMind Balance',
    value: 'wemind_balance',
  },
  {
    name: 'Movement',
    value: 'movement',
  },
  {
    name: 'Arbolink',
    value: 'arbolink',
  },
  {
    name: 'VRET Moovd: Trainingen',
    value: 'vret_moovd',
  },
];

export const tempListCompanyForSuperAdmin: ListCompanySuperAdminResponse[] = [
  {
    id: '1',
    companyName: 'MOOVD B.V',
    productName: 'Boost',
    firstName: 'Axel',
    lastName: 'Eldrian',
    departments: ['Kelas 1A', 'Kelas 2A', 'Kelas 3A'],
    seats: 100,
    licenseType: 'Boost License',
    status: 'active',
  },
  {
    id: '2',
    companyName: 'Dynalytics Inc.',
    productName: 'Boost',
    firstName: 'Olivia',
    lastName: 'Martinez',
    departments: ['Kelas 1B', 'Kelas 2B', 'Kelas 3B'],
    seats: 50,
    licenseType: 'Boost License',
    status: 'active',
  },
  {
    id: '3',
    companyName: 'Teknova Ltd.',
    productName: 'WeMind Balance',
    firstName: 'Ethan',
    lastName: 'Wright',
    departments: ['Kelas 1C', 'Kelas 2C', 'Kelas 3C'],
    seats: 150,
    licenseType: 'WeMind License',
    status: 'inactive',
  },
  {
    id: '4',
    companyName: 'InspireHub',
    productName: 'Boost',
    firstName: 'Sophia',
    lastName: 'Lee',
    departments: ['Kelas 1D', 'Kelas 2D', 'Kelas 3D'],
    seats: 75,
    licenseType: 'Boost License',
    status: 'waiting',
  },
  {
    id: '5',
    companyName: 'Visionary Solutions',
    productName: 'WeMind Balance',
    firstName: 'Liam',
    lastName: 'Chen',
    departments: ['Kelas 1E', 'Kelas 2E', 'Kelas 3E'],
    seats: 120,
    licenseType: 'WeMind License',
    status: 'waiting',
  },
  {
    id: '6',
    companyName: 'NovaTech',
    productName: 'Boost',
    firstName: 'Ava',
    lastName: 'Johnson',
    departments: ['Kelas 1F', 'Kelas 2F', 'Kelas 3F'],
    seats: 200,
    licenseType: 'Boost License',
    status: 'active',
  },
];
