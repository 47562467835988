<app-breadcrumb />
<div class="page">
  <app-filter-bar
    [(searchKey)]="modelSearchKey"
    [(selectedData)]="modelSelectedData().length"
    [totalData]="dataSource().filteredData.length"
    (buttonAction)="addCompany()"
    (reset)="resetFilter()"
  >
    <app-radio-input
      [title]="'status'"
      [options]="statusOptions"
      [control]="filterForm.controls[field.LICENSE_STATUS]"
    ></app-radio-input>
    <app-radio-input
      [title]="'active date'"
      [options]="activeDateOptions"
    ></app-radio-input>
    <app-multiple-chip-input [title]="'license'" [options]="licenseOptions">
    </app-multiple-chip-input>
  </app-filter-bar>

  <app-card>
    <div class="table w-full">
      <app-table
        [(loading)]="loading"
        [dataSource]="dataSource()"
        [headers]="headers"
        [uniqueValues]="unique"
        [(selectedData)]="modelSelectedData"
        [contentName]="'company'"
        [showAddButton]="true"
        (noDataButtonAction)="addCompany()"
        (viewDetail)="openViewDialog($event)"
      >
      </app-table>
    </div>

    <div class="paginator w-full">
      <mat-paginator
        [pageSizeOptions]="[10, 25, 100]"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </app-card>
</div>
