import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { FormBase } from '@classes';
import {
  FormComponent,
  FormFieldComponent,
  InputComponent,
} from '@components';
import { SessionStorageKeyEnum } from '@enums';

import { CompanyDepartmentFormField, FormCompanyDepartmentInterface, CompanyField } from '@app/feature/company/models';
import { CompanyService, StorageService } from '@services';
import { MoovdButton, MoovdIcon, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-company-department-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormComponent,
    FormFieldComponent,
    InputComponent,
    MoovdButton,
    MoovdLabel,
    MoovdIcon
  ],
  templateUrl: './company-department-form.component.html',
  styleUrl: './company-department-form.component.scss',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CompanyDepartmentFormComponent extends FormBase<
FormCompanyDepartmentInterface,
unknown
>{
  private route = inject(Router);

  public override form = CompanyDepartmentFormField;
  public readonly field = CompanyField;

  public departmentList = signal<string[]>([]);

  public departmentForm = new FormGroup({
    department: new FormControl<string>(''),
  });

  private formStep = this.companyService.formStepEnum;

  constructor(
    private companyService: CompanyService,
    private storageService: StorageService,
  ){
    super();
  }

  ngOnInit() {
    this.loadSavedFormData();
  }

  public addDepartment() {
    const departmentValue = this.departmentForm.controls.department.value;

    if (departmentValue !== null && departmentValue.trim()) {
      if (!this.departmentList().includes(departmentValue)) {
        this.departmentList.update((currentData) => [
          departmentValue,
          ...currentData,
        ]);
        this.departmentForm.controls.department.reset();
        this.form.controls[this.field.DEPARTMENT_LIST].setValue(
          this.departmentList()
        );
      }
    }
  }

  public removeDepartment(department: string) {
    this.departmentList.update((currentData) =>
      currentData.filter(
        (currentDepartment) => currentDepartment !== department
      )
    );
    this.form.controls[this.field.DEPARTMENT_LIST].setValue(this.departmentList());
  }

  public async back() {
    await this.route.navigate(['superadmin/companies/create/admin']);
    this.companyService.formStep.set(this.formStep.COMPANY_ADMIN)
  }

  public async next() {
    this.storageService.sessionStorageSet(
      SessionStorageKeyEnum.COMPANY_DEPARTMENT_FORM,
      {
        [this.field.DEPARTMENT_LIST]:
          this.form.controls[this.field.DEPARTMENT_LIST].value,
      }
    );
    this.route.navigate(['superadmin/companies/create/product']);
    this.companyService.formStep.set(this.formStep.COMPANY_PRODUCT)
  }

  
  private loadSavedFormData(): void {
    const savedData =
    this.storageService.sessionStorageGet<FormCompanyDepartmentInterface>(
      SessionStorageKeyEnum.COMPANY_DEPARTMENT_FORM
    );
    
    if(savedData){
      this.form.setValue(savedData);
      this.departmentList.set(savedData[this.field.DEPARTMENT_LIST]);
    }
  }
  
  protected override onSubmit(
    data: FormCompanyDepartmentInterface
  ): Observable<unknown> {
    return of([]);
  }
}
