import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonDirective,
  DropdownDirective,
  DropdownComponent,
} from '@app/shared/directives';
import { AvatarComponent } from '@app/shared/components';
import { languageSwitchAnimations } from '../language-switch/language-switch.animation';

import { MobileService } from 'src/app/core/services';

import { TranslateModule } from '@ngx-translate/core';
import { signOut } from 'aws-amplify/auth';
import { AuthService } from '@app/core/services/auth/auth.service';
import { Router } from '@angular/router';
import { MoovdButton, MoovdLabel } from 'moovd-components';

@Component({
  selector: 'app-user-profile-button',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    DropdownDirective,
    AvatarComponent,
    FontAwesomeModule,
    DropdownComponent,
    TranslateModule,
    MoovdButton,
    MoovdLabel
  ],
  templateUrl: './user-profile-button.component.html',
  styleUrl: './user-profile-button.component.scss',
  animations: [languageSwitchAnimations.indicatorRotate],
})
export class UserProfileButtonComponent {
  @ViewChild(DropdownDirective)
  public dropdown?: DropdownDirective;

  @Input()
  public square = false;

  @Input()
  public size: 'lg' | 'md' = 'lg';

  @Input()
  public stretch = false;

  @HostBinding('class')
  public get classes(): Array<string> {
    const array: Array<string> = [this.size];

    this.square && array.push('square');
    return array;
  }

  public isOpen = false;

  constructor(
    public mobileService: MobileService,
    public authService: AuthService,
    private router: Router
  ) {}

  public onOpened(): void {
    this.isOpen = true;
  }

  public onClosed(): void {
    this.isOpen = false;
  }

  public goPage(url: string): void {
    return console.log(url);
  }

  public async signOut() {
    await signOut();
    return await this.router.navigate(['auth']);
  }
}
