<app-dialog [disableClose]="getProgress() === -1?false:true" (closeClicked)="getProgress() === -1?cancel():null">
  <div class="container">
    @if (getProgress() === 100) {
    <div class="icon-container">
      <fa-icon icon="circle-check"></fa-icon>
    </div>
    }@else if (getProgress() === -1) {
    <div class="icon-container error">
      <fa-icon icon="circle-xmark"></fa-icon>
    </div>
    } @else {
    <div class="icon-container animate">
      <fa-icon icon="hourglass-start"></fa-icon>
    </div>

    } @if (getProgress() === -1) {
    <h4>Error</h4>
    <p class="description">
      {{ getMessage() }}
    </p>
    }@else{
    <h4>{{ data.title }}</h4>
    <p class="description">
      {{ data.desc }}
    </p>
    } 
    
    
    
    @if (getProgress() !== -1) {
    <div class="progress-bar-container">
      <app-progress-bar [value]="getProgress()"></app-progress-bar>
      <p>
        {{ getProgress() + "% " }}
        {{ getMessage() }}
      </p>
    </div>
    }

    <!-- @if (getProgress() === 100) {
      <div class="success-container">
        <fa-icon icon="circle-check"></fa-icon>
        <p>
          {{ 'signup.popup.success' | translate }}
        </p>
      </div>
    } -->
    <!-- 
    @if (progressService.error() && progressService.getProgress() !== 100) {
      <div class="error-container">
        <fa-icon icon="circle-exclamation"></fa-icon>
        <p>
          {{ 'signup.popup.failed' | translate }}
        </p>
      </div>
    } -->
  </div>
</app-dialog>
