<div #container id="container">
  <button
    #button
    appButton
    [class.has-value]="!!control.value"
    [appDropdown]="dropdown"
    [width]="width()"
    (closed)="panelClosed()"
    type="button"
    class="input"
    [offsetY]="8"
    [disabled]="isDisabled || !options?.length"
    (opened)="onPanelOpened()"
  >
    @if (icon) {
    <fa-icon [icon]="icon" class="icon"></fa-icon>
    }
    <!-- [class.danger]="isInvalid" -->
    @if (selectedOption && !checkbox) {
    <app-option-label
      class="select-label"
      [option]="selectedOption"
    ></app-option-label>
    } @else {
      <moovd-label class="placeholder"> {{ placeholder() }}</moovd-label>
    }

    <fa-icon
      [@chevronRotate]="panelState()"
      class="chevron"
      icon="chevron-down"
    ></fa-icon>
    <!-- <label *ngIf="label" [class.danger]="isInvalid">{{ label }}</label> -->
  </button>
</div>

@if (checkbox) {
<div class="chip-wrap" [ngClass]="{ 'margin-top': reverseOptions?.length !== 0 }">
  @if (reverseOptions?.length !== 0) {
  <span class="chip-text">Values: </span>
  } @for (option of reverseOptions; track $index) {
  <app-chip
    (removed)="removeChip($index, option.value)"
    [color]="option.value === 'all_company' ? 'yellow' : 'default'"
  >
  <moovd-label class="placeholder"> {{ option.name }}</moovd-label>
  </app-chip>
  }
</div>
}

<ng-template #dropdown>
  <div class="dropdown-wrapper">
    <app-select-panel
      [filterable]="filterable"
      [filterPlaceholder]="filterPlaceholder"
      [filterThreshold]="filterThreshold"
      [options]="options"
      [control]="control"
      (selected)="onSelect()"
    ></app-select-panel>
  </div>
</ng-template>
