import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  computed,
  signal,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';

import { InputBaseClass } from '@classes';
import { OptionInterface } from '@interfaces';
import {
  ButtonDirective,
  DropdownComponent,
  DropdownDirective,
} from '@directives';
import { SelectPanelComponent } from '../select-panel/select-panel.component';
import { OptionLabelComponent } from '../../label';

import { selectAnimation } from './select-animation';
import { ChipComponent } from '../../chip/chip.component';
import { MoovdLabel } from 'moovd-components';
@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    CommonModule,
    ButtonDirective,
    OptionLabelComponent,
    SelectPanelComponent,
    DropdownDirective,
    DropdownComponent,
    FontAwesomeModule,
    ChipComponent,
    MoovdLabel
  ],
  templateUrl: './select.component.html',
  animations: [selectAnimation.chevronRotate],
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent
  extends InputBaseClass
  implements OnInit, OnDestroy, AfterContentInit
{
  @Input()
  public currentData: any = undefined;

  @Input()
  public checkbox = false;

  @Input()
  public options?: Array<OptionInterface>;

  @Input()
  public filterable = false;

  @Input()
  public filterPlaceholder = '';

  @Input()
  public filterThreshold = 7;

  @Input()
  public variant: 'default' | 'rounded' = 'default';

  @Input()
  public size: 'md' | 'sm' | 'xs' = 'md';

  @Output()
  public checkboxFinalValue = new EventEmitter<void>();

  @ViewChild(DropdownDirective)
  public panel!: DropdownDirective;

  @ViewChild(SelectPanelComponent)
  public selectPanel?: SelectPanelComponent;

  @HostBinding('class')
  public get classes(): Array<string> {
    return [this.variant, this.size];
  }

 
  public chips: string[] = [];
  public width = signal<number>(0);
  public panelOpen = signal(false);

  public panelState = computed(() => {
    return this.panelOpen() ? 'opened' : 'closed';
  });

  public get selectedOption(): OptionInterface | undefined {
    return this.options?.find((o) => o.value === this.control.value);
  }

  private subs: Array<Subscription> = [];
  private optionsTemp?: Array<OptionInterface> = [];
  public reverseOptions?: Array<OptionInterface> = [];

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  public ngAfterContentInit(): void {
    this.cd.markForCheck();

    const div = document.getElementById('container');
    const resizeObserver = new ResizeObserver((entries) => {
      // eslint-disable-next-line prefer-const
      for (let entry of entries) {
        this.width.set(entry.contentRect.width);
      }
    });

    resizeObserver.observe(div as HTMLElement);
  }

  public ngOnInit(): void {

    this.optionsTemp = this.options;
    if(this.currentData !== undefined){
      this.currentData.map((o:string) => {
        this.options = this.options?.filter(option => option.value !== o)
        const currentOption = this.optionsTemp?.filter(option => option.value === o)
        if(currentOption){
          this.reverseOptions?.push(currentOption[0]);
        }
        this.chips.push(o);
      }) 
    }

    
    this.subs.push(
      this.control.valueChanges.subscribe(() => {
        this.cd.markForCheck();
      })
    );
  }
  
  public ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public onPanelOpened(): void {
    this.panelOpen.set(true);
  }

  public panelClosed(): void {
    this.panelOpen.set(false);
  }

  public onSelect(): void {
    this.panelOpen.set(false);
    this.panel?.close();
    
    if(this.checkbox){
      if(!this.chips.includes(this.control.value)){

        if(this.control.value === 'all_company'){
          this.chips = ['all_company'];
          this.options = [];
          const removedOption = this.optionsTemp?.filter(option => option.value === this.control.value);
          if(removedOption){
            this.reverseOptions = [];
            this.reverseOptions?.push(removedOption[0]);
          }
        }else{
          this.chips.push(this.control.value);
          this.options = this.options?.filter(option => option.value !== this.control.value);
          const removedOption = this.optionsTemp?.filter(option => option.value === this.control.value);
          if(removedOption){
            this.reverseOptions?.push(removedOption[0]);
          }
        }

        this.control.setValue(this.chips);
      }
    }
  }

  public removeChip(index: number, chipValue:unknown) {
    this.chips.splice(index, 1);

    if(chipValue === 'all_company'){
      this.options = this.optionsTemp;
      if(this.reverseOptions){
        this.reverseOptions = this.reverseOptions.filter(option => option.value !== chipValue);
      }
    }else{
      if(this.optionsTemp){
        const option = this.optionsTemp.filter(option => option.value === chipValue); 
        this.options?.push(option[0]);
      }
  
      if(this.reverseOptions){
        this.reverseOptions = this.reverseOptions.filter(option => option.value !== chipValue);
      }
    }


    this.control.setValue(this.chips);
  }

  public override get isDisabled(): boolean {
    return true;
  }
}
