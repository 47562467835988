import { from, Observable } from 'rxjs';
import '../utils/getClientInfo/getClientInfo.mjs';
import { isWebWorker } from '../utils/isWebWorker.mjs';
import '../utils/retry/retry.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
class Reachability {
  networkMonitor(_) {
    const globalObj = isWebWorker() ? self : typeof window !== 'undefined' && window;
    if (!globalObj) {
      return from([{
        online: true
      }]);
    }
    return new Observable(observer => {
      observer.next({
        online: globalObj.navigator.onLine
      });
      const notifyOnline = () => {
        observer.next({
          online: true
        });
      };
      const notifyOffline = () => {
        observer.next({
          online: false
        });
      };
      globalObj.addEventListener('online', notifyOnline);
      globalObj.addEventListener('offline', notifyOffline);
      Reachability._observers.push(observer);
      return () => {
        globalObj.removeEventListener('online', notifyOnline);
        globalObj.removeEventListener('offline', notifyOffline);
        Reachability._observers = Reachability._observers.filter(_observer => _observer !== observer);
      };
    });
  }
  // expose observers to simulate offline mode for integration testing
  static _observerOverride(status) {
    for (const observer of this._observers) {
      if (observer.closed) {
        this._observers = this._observers.filter(_observer => _observer !== observer);
        continue;
      }
      observer?.next && observer.next(status);
    }
  }
}
Reachability._observers = [];
export { Reachability };
