import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChipColor } from '@types';

@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss',
})
export class ChipComponent {
  public color = input<ChipColor>('default');

  public removeable = input<boolean>(false);

  public readonly delete = output();

  public onDelete() {
    this.delete.emit();
  }
}
