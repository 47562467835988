import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { FilterBarComponent } from '@app/shared/components/filter-bar/filter-bar.component';
import { MultipleChipInputComponent } from '@app/shared/components/inputs/multiple-chip-input/multiple-chip-input.component';
import { RadioInputComponent } from '@app/shared/components/inputs/radio-input/radio-input.component';
import {
  BreadcrumbComponent,
  CardComponent,
  TableComponent,
} from '@components';
import { TranslateModule } from '@ngx-translate/core';

import {
  statusOptions,
  activeDateOptions,
  licenseOptions,
} from '../../datas/temporary.data';
import {
  CompanyField,
  CompanyFilterFormField,
  ListCompaniesOverviewResponse,
} from '../../models';

import { header } from '../../models';
import { uniqueValue } from '../../models';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { LicenseStatus, ProductName } from '@types';
import { CompanyService, DialogService } from '@services';
import { CompanyViewDialogComponent } from '../../components/company-view-dialog/company-view-dialog.component';

@Component({
  selector: 'app-company-list',
  standalone: true,
  imports: [
    TranslateModule,
    BreadcrumbComponent,
    CardComponent,
    FilterBarComponent,
    RadioInputComponent,
    MultipleChipInputComponent,
    TableComponent,
    MatPaginator,
    MatPaginatorModule,
  ],
  templateUrl: './company-list.component.html',
  styleUrl: './company-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListComponent {
  private paginator = viewChild<MatPaginator>(MatPaginator);
  private dialogService = inject(DialogService);

  protected modelSearchKey = signal<string>('');
  protected modelSelectedData = signal<any[]>([]);
  protected selectedData = effect(() => {
    return this.modelSelectedData().map((data) => data.id);
  });

  public statusOptions = statusOptions;
  public activeDateOptions = activeDateOptions;
  public licenseOptions = licenseOptions;

  public field = CompanyField;
  public filterForm = CompanyFilterFormField;
  public headers = header;
  public unique = uniqueValue;

  public loading = signal<boolean>(false);
  public dataSource = signal<MatTableDataSource<ListCompaniesOverviewResponse>>(
    new MatTableDataSource()
  );

  constructor(private router: Router, private companyService: CompanyService) {
    effect(() => {
      this.dataSource().filter = this.modelSearchKey().trim().toLowerCase();
      if (this.dataSource().paginator) {
        this.dataSource().paginator?.firstPage;
      }
    });
  }

  ngOnInit() {
    this.load();
    this.dataSource().paginator = this.paginator() as MatPaginator;

    this.filterForm.valueChanges.subscribe((res) => {
      if (res.status) {
        this.dataSource().filter = res.status;
      } else {
        this.dataSource().filter = '';
      }
    });
  }

  protected openViewDialog(data: ListCompaniesOverviewResponse){
    const licenseId = data.licenseId;
    this.dialogService.open(CompanyViewDialogComponent, licenseId, true, true);
  }

  private load(filter?: {
    licenseStatus?: LicenseStatus;
    productName?: ProductName[];
  }): void {
    this.loading.set(true)
    this.companyService.listCompaniesOverview(filter)
    .subscribe({
      next: (res) => {   
        this.dataSource.set(new MatTableDataSource(res));
        this.loading.set(false);
        return res;
      }
    });
  }

  protected resetFilter() {
    this.filterForm.reset();
  }

  protected addCompany(): Promise<boolean> {
    return this.router.navigate(['superadmin/companies/create']);
  }
}
