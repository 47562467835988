import { ChangeDetectionStrategy, Component, input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-input-loading-message',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './input-loading-message.component.html',
  styleUrls: ['./input-loading-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputLoadingMessageComponent {
  public showMessage = input.required<boolean>();
  public message = input.required<string>();
}
