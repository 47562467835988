<app-breadcrumb />

<div class="page">
  <app-card
    [title]="'form.title.company-information' | translate"
  >
    <app-company-preview 
    [data]="data.data"/>
  </app-card>
</div>
